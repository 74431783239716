import { useEffect, useState } from "react";
import {
  getBrokerageData,
  getClientData,
} from "../../store/actions/investmentsAction";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

export default () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selected, setSelected] = useState(searchParams.get("page"));
  const [selectedChild, setSelectedChild] = useState("performance");

  const onPressMenu = (val) => {
    setSearchParams((params) => {
      params.set("page", val);
      return params;
    });
  };

  useEffect(() => {
    if (searchParams.get("page")) {
      setSelected(searchParams.get("page"));
    } else {
      setSearchParams((params) => {
        params.set("page", "overview");
        return params;
      });
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(getClientData());
    dispatch(getBrokerageData());
  }, []);

  return {
    selected,
    onPressMenu,
    selectedChild,
    setSelectedChild,
  };
};
