import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientDetails: null,
  brokerage: null,
};

const investmentsSlice = createSlice({
  name: "investments",
  initialState,
  reducers: {
    setClientDetails: (state, action) => {
      state.clientDetails = action.payload;
    },
    setBrokerage: (state, action) => {
      state.brokerage = action.payload;
    },
  },
});

export const { setClientDetails, setBrokerage } = investmentsSlice.actions;
export default investmentsSlice.reducer;
