import React from "react";
import dynamicfunction from "../utils/dynamicfunction";

const Text = ({
  text = "",
  weight,
  size,
  color = "text-dark-grey",
  className,
  isRequired,
  isColon = false,
  onClick
}) => {
  const { TextWeight, TextAlign, isArabic } = dynamicfunction();

  return (
    <p
      style={{
        fontSize: size ? size : 12,
      }}
      className={` ${color}  ${
        weight ? weight : TextWeight.regular
      }  ${TextAlign}  ${className} `}
      onClick={onClick}
    >
      {isColon ? (isArabic ? `:${text}` : `${text}:`) : text}

      {isRequired && <span className="text-red-500">*</span>}
    </p>
  );
};

export default Text;
