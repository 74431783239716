import apiServices from "../../ApiServices/request-handler";
import {
  setAuthLoading,
  setLoginError,
  setOtpError,
  setUserToken,
} from "../slices/authSlice";

export const loginUser = (data, onDone) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await apiServices.login(data);
      dispatch(setAuthLoading(false));
      onDone();
    } catch (error) {
      dispatch(setAuthLoading(false));
      dispatch(setLoginError(error?.data?.message));
    }
  };
};

export const verifyUserOtp = (data, onDone) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await apiServices.verifyOtp(data);
      dispatch(setAuthLoading(false));
      dispatch(setUserToken({ token: response.result }));
      onDone();
    } catch (error) {
      console.log(error)
      dispatch(setAuthLoading(false));
      dispatch(setOtpError(error?.data?.message || error?.data?.errors?.OtpCode?.[0]));
    }
  };
};

export const resendOtpCode = (data, onDone) => {
  return async (dispatch) => {
    try {
      const response = await apiServices.resendCode(data);
      onDone();
    } catch (error) {
    }
  };
};
