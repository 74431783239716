import React from "react";
import Sidebar from "../../components/Sidebar";
import investmentsFunctional from "./index.function";
import { investmentsMenu } from "../../config/config";
import Overview from "./components/Overview";
import Brokerage from "./components/Brokerage";
import Allocation from "./components/Allocation";
import Holdings from "./components/Holdings";
import Performance from "./components/Performance";

const MyInvestments = () => {
  const { selected, onPressMenu, selectedChild, setSelectedChild } =
    investmentsFunctional();

  return (
    <Sidebar
      selected={selected}
      onPressMenu={onPressMenu}
      menuList={investmentsMenu}
      selectedChild={selectedChild}
      setSelectedChild={setSelectedChild}
    >
      {selected === "overview" ? (
        <Overview />
      ) : selected === "manage_investment" ? (
        selectedChild === "performance" ? (
          <Performance />
        ) : selectedChild === "allocation" ? (
          <Allocation />
        ) : (
          <Holdings />
        )
      ) : selected === "brokerage" ? (
        <Brokerage />
      ) : null}
    </Sidebar>
  );
};

export default MyInvestments;
