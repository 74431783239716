const englishFonts = {
    butlerBlack: 'butler-black',
    butlerBold: 'butler-bold',
    butlerLight: 'butler-light',
    butlerMedium: 'butler-medium',
    butlerRegular: 'butler-regular',
    avenirBlack: 'avenir-black',
    avenirLight: 'avenir-light',
    avenirMedium: 'avenir-medium',
    avenirHeavy: 'avenir-heavy',
}

const arabicFonts = {
    almaraiBold: 'almarai-bold',
    almaraiExtraBold: 'almarai-extra-bold',
    almaraiLight: 'almarai-light',
    almaraiRegular: 'almarai-regular',
};

const fontSizes = {
    h8: 8,
    h9: 9,
    h10: 10,
    h11: 11,
    h12: 12,
    h13: 13,
    h14: 14,
    h15: 15,
    h16: 16,
    h17: 17,
    h18: 18,
    h19: 19,
    h20: 20,
    h21: 21,
    h22: 22,
    h23: 23,
    h24: 24,
    h25: 25,
    h26: 26,
    h27: 27,
    h28: 28,
    h29: 29,
    h30: 30,
    h31: 31,
    h32: 32,
    h33: 33,
    h34: 34,
    h35: 35,
    h36: 36,
    h37: 37,
    h38: 38,
    h39: 39,
    h40: 40,
    h41: 41,
    h42: 42,
    h43: 43,
    h44: 44,
    h45: 45,
    h46: 46,
    h47: 47,
    h48: 48,
    h49: 49,
    h50: 50,
    h60: 60,
    h70: 70,
    h80: 80,
  };
  

export { englishFonts, arabicFonts, fontSizes };