import apiServices from "../../ApiServices/request-handler";
import { showToast } from "../../utils/utils";
import { setBrokerage, setClientDetails } from "../slices/investmentsSlice";


const getClientData = () => {
    return async (dispatch) => {
        try {
            const response = await apiServices.getClientDetails();
            dispatch(setClientDetails(response?.result));
        } catch (error) {
            showToast('error', error?.message)
        }
    }
}

const getBrokerageData = () => {
    return async (dispatch) => {
        try {
            const response = await apiServices.getBrokerage();
            dispatch(setBrokerage(response?.result));
        } catch (error) {
            showToast('error', error?.message)
        }
    }
}

export {
    getClientData,
    getBrokerageData,
};