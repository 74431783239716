import React, { useState } from "react";
import ViewItems from "../../../components/ViewItems";
import ViewReverse from "../../../components/ViewReverse";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import Button from "../../../components/Button";
import { ReactSVG } from "react-svg";
import icons from "../../../constants/icons";
import { ConfigProvider, Table, Tabs } from "antd";
import {
  orderBasketColumns,
  orderBasketData,
  portfolioColumns,
  portfolioData,
  priceSpectrum,
  watchListColumns,
  watchListData,
} from "../../../config/config";
import ProgressBar from "../../../components/ProgressBar";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";

const TradingOverview = () => {
  const { marketSummary, portfolioSummary } = useSelector(state => state.trading);
  const { TextWeight, isArabic } = dynamicfunction();

  const [isDetailOpen, setIsDetailOpen] = useState({
    watchList: true,
    priceSpectrum: true,
    protfolioSummary: true,
    portfolioPosition: true,
    orderBasket: true,
    transactionTicket: true,
  });

  const toogleFullScreen = (key) =>
    setIsDetailOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

  return (
    <div>
      <ViewReverse className={"justify-between"}>
        <Text
          text="Overview"
          weight={TextWeight.headingBold}
          size={fontSizes.h24}
          color="text-dark-blue"
        />

        <ViewReverse className={"gap-3"}>
          <ViewReverse className={"items-center gap-2 me-3"}>
            <div className="h-4 w-4 rounded-full bg-green-500" />
            <Text
              text="connected"
              className={"uppercase opacity-50"}
              size={fontSizes.h10}
              weight={TextWeight.bold}
              color="text-dark-grey"
            />
          </ViewReverse>
          <Button
            text={"Buy"}
            bgColor="bg-light-blue"
            weight={TextWeight.extraBold}
            className={"px-5"}
          />
          <Button
            text={"Sell"}
            bgColor="bg-dark-blue"
            weight={TextWeight.extraBold}
            className={"px-5"}
          />
        </ViewReverse>
      </ViewReverse>

      {
        marketSummary ?
      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3 pb-10"}>
        <ViewReverse className={"w-full items-center justify-between mb-2"}>
          <ViewReverse className={"gap-2"}>
            <Text
              text="Market Summary"
              color="text-primary-light"
              size={fontSizes.h14}
            />
            <Text
              text="Close"
              color="text-red-500"
              weight={TextWeight.bold}
              size={fontSizes.h14}
            />
          </ViewReverse>
          <ViewReverse className={"items-center gap-2"}>
            <div className="h-3 w-3 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
          </ViewReverse>
        </ViewReverse>
        <ViewReverse className={"gap-12"}>
          <ViewItems>
            <ViewReverse className={"items-center gap-2"}>
              <Text
                text={marketSummary?.totalBalance}
                size={fontSizes.h22}
                weight={TextWeight.light}
              />
              <ReactSVG src={marketSummary?.totalMarketProfitOrLossPercentage > 0 ? icons.up : icons.down} />
            </ViewReverse>
            <Text
              text={`${marketSummary?.totalMarketProfitOrLoss} (${marketSummary?.totalMarketProfitOrLossPercentage}%)`}
              size={fontSizes.h14}
              color={marketSummary?.totalMarketProfitOrLossPercentage > 0 ? "text-green-500" : "text-red-500"}
              weight={TextWeight.bold}
            />
          </ViewItems>
          <ViewItems className={"gap-1"}>
            <Text
              text="Turnover"
              size={fontSizes.h14}
              className={"opacity-40"}
            />
            <Text text={marketSummary?.turnover?.toLocaleString()} size={fontSizes.h14} />
          </ViewItems>
          <ViewItems className={"gap-1"}>
            <Text
              text="T. Volume"
              size={fontSizes.h14}
              className={"opacity-40"}
            />
            <Text text={marketSummary?.tVolume?.toLocaleString()} size={fontSizes.h14} />
          </ViewItems>
          <ViewItems className={"gap-1"}>
            <Text text="Trades" size={fontSizes.h14} className={"opacity-40"} />
            <Text text={marketSummary?.trades?.toLocaleString()} size={fontSizes.h14} />
          </ViewItems>
          <ViewItems className={"gap-1"}>
            <Text text="Traded" size={fontSizes.h14} className={"opacity-40"} />
            <Text text={marketSummary?.traded?.toLocaleString()} size={fontSizes.h14} />
          </ViewItems>
          <ViewItems>
            <ViewReverse>
              <ReactSVG src={icons.up} className="mt-2 me-2" />
              <ViewItems className={"gap-1"}>
                <Text text="Up" size={fontSizes.h14} className={"opacity-40"} />
                <Text text={marketSummary?.up?.toLocaleString()} size={fontSizes.h14} />
              </ViewItems>
            </ViewReverse>
          </ViewItems>
          <ViewItems>
            <ViewReverse>
              <ReactSVG src={icons.down} className="mt-2 me-2" />
              <ViewItems className={"gap-1"}>
                <Text
                  text="Down"
                  size={fontSizes.h14}
                  className={"opacity-40"}
                />
                <Text text={marketSummary?.down?.toLocaleString()} size={fontSizes.h14} />
              </ViewItems>
            </ViewReverse>
          </ViewItems>
        </ViewReverse>
      </ViewItems>
      : <Loader/>
      }


      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3 gap-3"}>
        <ViewReverse className={"w-full items-center justify-between"}>
          <Text
            text="Watch List"
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-red-500 rounded-full" />
            <ReactSVG src={icons.plus} className="opacity-20" />
            <ReactSVG src={icons.settings} className="opacity-40" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.watchList
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("watchList")}
            />
          </ViewReverse>
        </ViewReverse>

        {isDetailOpen.watchList ? (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "#E0E0E0",
                  headerSplitColor: "transparent",
                  borderRadius: 0,
                  padding: 10,
                },
              },
            }}
          >
            <Table
              columns={
                isArabic
                  ? watchListColumns(TextWeight).reverse()
                  : watchListColumns(TextWeight)
              }
              dataSource={watchListData}
              pagination={false}
              className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
              scroll={{
                x: "max-content",
                y: 250,
              }}
              onRow={(i) => ({
                onClick: (e) => {},
              })}
            />
          </ConfigProvider>
        ) : null}
      </ViewItems>

      <ViewReverse className={"w-full gap-5"}>
        <ViewItems
          className={"w-1/2 border border-grey mt-6 rounded px-5 py-3"}
        >
          <ViewReverse className={"w-full items-center justify-between mb-3"}>
            <Text
              text="Price spectrum"
              color="text-primary-light"
              size={fontSizes.h14}
            />
            <ViewReverse className={"gap-2 items-center"}>
              <div className="w-4 h-4 bg-orange-500 rounded-full" />
              <ReactSVG src={icons.filter} />
              <ReactSVG
                className="cursor-pointer"
                src={
                  isDetailOpen.priceSpectrum
                    ? icons.smallScreen
                    : icons.enterFullScreen
                }
                onClick={() => toogleFullScreen("priceSpectrum")}
              />
            </ViewReverse>
          </ViewReverse>
          {isDetailOpen.priceSpectrum ? (
            <>
              <ViewReverse className={"w-full bg-grey py-1 rounded mb-3"}>
                <Text
                  text="Bids"
                  size={fontSizes.h14}
                  weight={TextWeight.bold}
                  className={"w-2/5 text-center"}
                />
                <Text
                  text="Price"
                  size={fontSizes.h14}
                  weight={TextWeight.bold}
                  className={"w-1/5 text-center"}
                />
                <Text
                  text="Offers"
                  size={fontSizes.h14}
                  weight={TextWeight.bold}
                  className={"w-2/5 text-center"}
                />
              </ViewReverse>
              <div className="w-full">
                {priceSpectrum.map((item, index) => (
                  <ViewReverse className="border-b border-b-grey">
                    <div className="w-2/5">
                      <ProgressBar
                        text={item.bid}
                        percentage={item.bidPercentage}
                      />
                    </div>
                    <div className="w-1/5">
                      <Text
                        text={item.price}
                        size={fontSizes.h14}
                        className={"text-center"}
                      />
                    </div>
                    <div className="w-2/5">
                      <ProgressBar
                        text={item.offer}
                        isred
                        isTextRight
                        percentage={item.offerPercentage}
                      />
                    </div>
                  </ViewReverse>
                ))}
              </div>
              <ViewReverse className={"w-full bg-grey py-1 rounded mt-3"}>
                <ViewReverse className={"w-2/5 justify-center gap-1"}>
                  <Text
                    text="T. Bids"
                    color="text-light-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="52,636 (52)"
                    color="text-green-500"
                    size={fontSizes.h14}
                  />
                </ViewReverse>
                <div className="w-1/5" />
                <ViewReverse className={"w-2/5 justify-center gap-1"}>
                  <Text
                    text="T. Offers"
                    color="text-light-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="136,692 (37)"
                    color="text-red-500"
                    size={fontSizes.h14}
                  />
                </ViewReverse>
              </ViewReverse>
            </>
          ) : null}
        </ViewItems>
        <ViewItems
          className={"w-1/2 border border-grey mt-6 rounded px-5 py-3"}
        >
          <ViewReverse className={"w-full items-center justify-between mb-3"}>
            <Text
              text="Portfolio summary"
              color="text-primary-light"
              size={fontSizes.h14}
            />
            <ViewReverse className={"gap-2 items-center"}>
              <div className="w-4 h-4 bg-grey rounded-full" />
              <ReactSVG src={icons.filter} />
              <ReactSVG src={icons.roundArrow} className="opacity-25" />
              <ReactSVG
                className="cursor-pointer"
                src={
                  isDetailOpen.protfolioSummary
                    ? icons.smallScreen
                    : icons.enterFullScreen
                }
                onClick={() => toogleFullScreen("protfolioSummary")}
              />
            </ViewReverse>
          </ViewReverse>

          {isDetailOpen.protfolioSummary ? (
            <>
              <ViewReverse className={"w-full gap-6"}>
                <div className="flex flex-col items-center w-4/5 py-1 bg-light-blue bg-opacity-20 rounded">
                  <Text
                    text="Purchase Power"
                    size={fontSizes.h14}
                    color="text-dark-blue"
                  />
                  <Text
                    text={portfolioSummary?.purchasePower?.toLocaleString()}
                    size={fontSizes.h22}
                    weight={TextWeight.bold}
                    color="text-dark-blue"
                  />
                </div>
                <div className="w-1/5 bg-grey flex justify-center items-center rounded">
                  <Text text="SAR" size={fontSizes.h14} />
                </div>
              </ViewReverse>

              <ViewReverse className={"w-full"}>
                <ViewItems className={"w-2/5 mt-4"}>
                  <Text
                    text="Cash"
                    color="text-light-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={portfolioSummary?.cash?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="Buy in Transit"
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.buyInTransit?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="Portfolio Value"
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.portfolioValue?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
                <ViewItems className={"mt-4"}>
                  <Text
                    text="Blocked"
                    color="text-light-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={portfolioSummary?.blocked?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="Limit"
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.limit?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="Coverage Ratio"
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="0.00"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="LTV Ratio"
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.ltvRatio?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
              </ViewReverse>

              <ViewReverse className={"gap-1 mt-6 mb-1"}>
                <Text
                  text="Cash Account"
                  color="text-primary-light"
                  size={fontSizes.h14}
                />
                <Text
                  text="1234567890"
                  color="text-primary-light"
                  weight={TextWeight.bold}
                  size={fontSizes.h14}
                />
              </ViewReverse>

              <div className="h-[2px] bg-grey w-full" />

              <ViewReverse className={"w-full"}>
                <ViewItems className={"w-2/5"}>
                  <Text
                    text="Holding Value"
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="8,448,562.87"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="Unsettled Buy"
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="8,448,562.87"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
                <ViewItems>
                  <Text
                    text="Acc. Limit"
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="0.00"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="Unsettled Sell"
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="8,448,562.87"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
              </ViewReverse>
            </>
          ) : null}
        </ViewItems>
      </ViewReverse>

      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3 gap-2"}>
        <ViewReverse className={"w-full justify-between"}>
          <Text
            text="Portfolio position - <Name of portfolio>"
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
            <ReactSVG src={icons.roundArrow} className="opacity-25" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.portfolioPosition
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("portfolioPosition")}
            />
          </ViewReverse>
        </ViewReverse>

        {isDetailOpen.portfolioPosition ? (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "#E0E0E0",
                  headerSplitColor: "transparent",
                  borderRadius: 0,
                  padding: 15,
                },
              },
            }}
          >
            <Table
              columns={portfolioColumns(TextWeight)}
              dataSource={portfolioData}
              pagination={false}
              className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
              scroll={{
                x: "max-content",
                y: 250,
              }}
              locale={{ emptyText: "No data available in table" }}
              onRow={(i) => ({
                // onClick: (e) => {
                // },
              })}
            />
          </ConfigProvider>
        ) : null}
      </ViewItems>

      <ViewItems
        className={"border border-grey mt-6 rounded px-5 py-3 gap-2 w-full"}
      >
        <ViewReverse className={"w-full justify-between"}>
          <Text
            text="Order basket"
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
            <ReactSVG src={icons.roundArrow} className="opacity-25" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.orderBasket
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("orderBasket")}
            />
          </ViewReverse>
        </ViewReverse>

        <ConfigProvider
          tabs={{
            style: {
              color: "red",
            },
          }}
          theme={{
            components: {
              Tabs: {
                cardBg: "white",
                // colorBorderSecondary: 'transparent',
              },
            },
          }}
        >
          <Tabs
            defaultActiveKey="1"
            type="card"
            className="w-full"
            items={new Array(3).fill(null).map((_, i) => {
              const id = String(i + 1);
              return {
                label: `Card Tab ${id}`,
                key: id,
                // children: `Content of card tab ${id}`,
              };
            })}
            tabBarExtraContent={
              <ViewReverse className={"gap-2 mb-1"}>
               
                <Button
                  text={"submit"}
                  color={"text-dark-grey"}
                  bgColor="bg-white"
                  className={
                    "border border-dark-grey w-32 px-0 text-center text-dark-grey text-opacity-30 border-opacity-30"
                  }
                  icon={icons.tick}
                  iconClassName="opacity-30 mb-1"
                />
               
                <Button
                  text={"simulate"}
                  color={"text-dark-grey"}
                  bgColor="bg-white"
                  className={
                    "border border-dark-grey w-32 px-0 text-center text-dark-grey text-opacity-30 border-opacity-30"
                  }
                  icon={icons.play}
                  iconClassName="opacity-30 mb-1"
                />
               
                <Button
                  text={"delete"}
                  color={"text-dark-grey"}
                  bgColor="bg-white"
                  className={
                    "border border-dark-grey w-32 px-0 text-center text-dark-grey text-opacity-30 border-opacity-30"
                  }
                  icon={icons.delete_icon}
                  iconClassName="opacity-30 mb-1"
                />
              </ViewReverse>
            }
          />
        </ConfigProvider>

        {isDetailOpen.orderBasket ? (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "#E0E0E0",
                  headerSplitColor: "transparent",
                  borderRadius: 0,
                  padding: 15,
                },
              },
            }}
          >
            <Table
              columns={orderBasketColumns(TextWeight)}
              dataSource={orderBasketData}
              pagination={false}
              className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
              scroll={{
                x: "max-content",
                y: 250,
              }}
              rowSelection={{
                type: "checkbox",
              }}
              locale={{ emptyText: "No data available in table" }}
              onRow={(i) => ({
                // onClick: (e) => {
                // },
              })}
            />
          </ConfigProvider>
        ) : null}
      </ViewItems>

      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3 gap-2"}>
        <ViewReverse className={"w-full justify-between"}>
          <Text
            text="Transaction Ticket"
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
            <ReactSVG src={icons.roundArrow} className="opacity-25" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.transactionTicket
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("transactionTicket")}
            />
          </ViewReverse>
        </ViewReverse>

        {isDetailOpen.transactionTicket ? (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "#E0E0E0",
                  headerSplitColor: "transparent",
                  borderRadius: 0,
                  padding: 15,
                },
              },
            }}
          >
            <Table
              columns={orderBasketColumns(TextWeight)}
              dataSource={orderBasketData}
              pagination={false}
              className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
              scroll={{
                x: "max-content",
                y: 250,
              }}
              rowSelection={{
                type: "checkbox",
              }}
              locale={{ emptyText: "No data available in table" }}
              onRow={(i) => ({
                // onClick: (e) => {
                // },
              })}
            />
          </ConfigProvider>
        ) : null}
      </ViewItems>
    </div>
  );
};

export default TradingOverview;
