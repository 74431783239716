import React, { useState } from "react";
import ViewItems from "../../../components/ViewItems";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import ViewReverse from "../../../components/ViewReverse";
import {
  buyModeOptions,
  cashAccountOptionds,
  fillTermsOptions,
  goodTillOptions,
  orderSideOptions,
  portfolioOptions,
  sellModeOptions,
} from "../../../config/config";
import CustomDropdown from "../../../components/CustomDropdown";
import CustomCheckbox from "../../../components/CustomCheckbox";
import Button from "../../../components/Button";
import { getContentKeyAccToLang } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { rowReverseStyle } from "../../../utils/styles";

const TradingSettings = () => {
  const { TextWeight, isArabic } = dynamicfunction();
  const { settingContent } = useSelector(state => state.content);

  const [tradingSettings, setTradingSettings] = useState({
    orderSide: "buy",
    goodTill: "day",
    buyMode: "limit",
    fillTerm: "market-default",
    sellMode: "stop-market-sell",
    portfolio: "regular-dfm",
    cashAccount: "0671127728901",
    isPricingServer: false,
    placement: false,
    bit_offer: false,
  });

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      settingContent?.web_trading_setting?.data?.attributes,
      key,
      isArabic
    );
  };

  const handleTradingSettings = (key, val) =>
    setTradingSettings((prev) => ({ ...prev, [key]: val }));

  return (
    <ViewItems>
      <Text
        text={getContentKey("heading")}
        weight={TextWeight.headingRegular}
        size={fontSizes.h24}
        color={"text-dark-blue"}
      />

      <ViewReverse className={`w-full items-center gap-[10%] mt-4 flex-col md:${rowReverseStyle(isArabic)}`}>
        <CustomDropdown
          label={getContentKey("order_side_label")}
          options={orderSideOptions}
          selectedValue={tradingSettings.orderSide}
          onSelect={(val) => handleTradingSettings("orderSide", val)}
        />
        <CustomDropdown
          label={getContentKey("good_label")}
          options={goodTillOptions}
          selectedValue={tradingSettings.goodTill}
          onSelect={(val) => handleTradingSettings("goodTill", val)}
          className={'mt-4 md:mt-0'}
        />
      </ViewReverse>

      <ViewReverse className={`w-full items-center gap-[10%] mt-4 flex-col md:${rowReverseStyle(isArabic)}`}>
        <CustomDropdown
          label={getContentKey("buy_mode")}
          options={buyModeOptions}
          selectedValue={tradingSettings.buyMode}
          onSelect={(val) => handleTradingSettings("buyMode", val)}
        />
        <CustomDropdown
          label={getContentKey("fill_term_label")}
          options={fillTermsOptions}
          selectedValue={tradingSettings.fillTerm}
          onSelect={(val) => handleTradingSettings("fillTerm", val)}
          className={'mt-4 md:mt-0'}
        />
      </ViewReverse>

      <ViewReverse className={`w-full items-center gap-[10%] mt-4 flex-col md:${rowReverseStyle(isArabic)}`}>
        <CustomDropdown
          label={getContentKey("sell_mode")}
          options={sellModeOptions}
          selectedValue={tradingSettings.sellMode}
          onSelect={(val) => handleTradingSettings("sellMode", val)}
        />
        <CustomDropdown
          label={getContentKey("portfolio_label")}
          options={portfolioOptions}
          selectedValue={tradingSettings.portfolio}
          onSelect={(val) => handleTradingSettings("portfolio", val)}
          className={'mt-4 md:mt-0'}
        />
      </ViewReverse>

      <ViewReverse className={`w-full items-start gap-[10%] mt-4 flex-col-reverse md:${rowReverseStyle(isArabic)}`}>
        <div className="w-full md:w-1/2 flex flex-col gap-4 mt-4 md:mt-0">
          <CustomCheckbox
            isChecked={tradingSettings.isPricingServer}
            label={getContentKey("pricing_server")}
            onClick={() =>
              handleTradingSettings(
                "isPricingServer",
                !tradingSettings.isPricingServer
              )
            }
          />
          <CustomCheckbox
            isChecked={tradingSettings.placement}
            label={getContentKey("placement")}
            onClick={() =>
              handleTradingSettings("placement", !tradingSettings.placement)
            }
          />
          <CustomCheckbox
            isChecked={tradingSettings.bit_offer}
            label={getContentKey("price_warning")}
            onClick={() =>
              handleTradingSettings("bit_offer", !tradingSettings.bit_offer)
            }
          />
        </div>
        <div className="w-full md:w-1/2">
          <CustomDropdown
            label={getContentKey("account_label")}
            options={cashAccountOptionds}
            selectedValue={tradingSettings.cashAccount}
            onSelect={(val) => handleTradingSettings("cashAccount", val)}
          />
        </div>
      </ViewReverse>

      <ViewReverse className="items-center w-full justify-end gap-2 mt-8 mb-6">
        <Button
          text={getContentKey('reset_btn')}
          color={"text-dark-grey"}
          bgColor="bg-white"
          className={
            "border border-dark-grey text-center w-32 border-opacity-50"
          }
        />
        <Button
          text={getContentKey('save_btn')}
          bgColor="bg-orange"
          className={"text-center w-32 border border-orange"}
        />
        <Button
          text={getContentKey('cancel_btn')}
          color={"text-dark-grey"}
          bgColor="bg-white"
          className={
            "border border-dark-grey text-center w-32 border-opacity-50"
          }
        />
      </ViewReverse>

    </ViewItems>
  );
};

export default TradingSettings;
