import React, { useState } from 'react'
import { fontSizes } from '../constants/constants'
import Text from './Text'
import { DatePicker } from 'antd'
import '../App.css';
import locale from 'antd/es/date-picker/locale/en_US';
import dynamicfunction from '../utils/dynamicfunction';

const CustomDatePicker = ({ label, divClasses, onChange, width = 'w-full' }) => {
  const { isArabic } = dynamicfunction();


  return (
    <div className={`${divClasses} ${width}`}>
      <Text
      text={label && label}
      size={fontSizes.h14}
      color={'text-primary-light'}
      className={'mb-1'}
      />
      
      <DatePicker
      onChange={onChange}
      needConfirm
      className={`w-full custom-calendar py-2 rounded ${isArabic ? "arabic-calendar" : ""}`}
      locale={{
        ...locale,
        lang: {
            ...locale.lang,
            ok: "Done",
        },
      }}
      />

    </div>
  )
}

export default CustomDatePicker