import { ReactSVG } from "react-svg";
import Text from "../components/Text";
import { fontSizes } from "../constants/constants";
import icons from "../constants/icons";

export const languages = {
  eng: "English",
  arb: "Arabic",
};

// tabs menu

export const tabsMenu = [
  {
    id: 0,
    key: "investments",
    route: "investments",
  },
  {
    id: 1,
    key: "funds",
    route: "funds",
  },
  {
    id: 2,
    key: "trading",
    route: "trading",
  },
  {
    id: 3,
    key: "transfers",
    route: "transfers",
  },
  {
    id: 4,
    key: "reports",
    route: "reports",
  },
  {
    id: 5,
    key: "settings",
    route: "settings",
  },
  {
    id: 6,
    key: "help",
    route: "help",
  },
];

// tabs dropdown menus

export const investmentsMenu = [
  {
    id: 0,
    label: "Overview",
    key: "overview",
    disableIcon: icons.home,
    activeIcon: icons.homeSelected,
  },
  {
    id: 1,
    label: "Managed investments",
    key: "manage_investment",
    disableIcon: icons.pieChart,
    activeIcon: icons.pieChartSelected,
    childs: [
      {
        key: "performance",
        label: "Performance",
      },
      {
        key: "allocation",
        label: "Allocation",
      },
      {
        key: "holdings",
        label: "Holdings",
      },
    ],
  },
  {
    id: 2,
    label: "Brokerage",
    key: "brokerage",
    disableIcon: icons.bag,
    activeIcon: icons.bagSelected,
  },
];

export const investmentsMenuOptions = (getContentKey) => [
  {
    id: 0,
    label: getContentKey("overview"),
    key: "overview",
  },
  {
    type: "divider",
  },
  {
    id: 1,
    label: getContentKey("manage_investment"),
    key: "manage_investment",
    childs: [
      {
        key: "performance",
        label: getContentKey("performance"),
      },
      {
        key: "allocation",
        label: getContentKey("allocation"),
      },
      {
        key: "holdings",
        label: getContentKey("holdings"),
      },
    ],
  },
  {
    type: "divider",
  },
  {
    id: 2,
    label: getContentKey("brokerage"),
    key: "brokerage",
  },
];

export const settingsMenu = [
  {
    id: 0,
    label: "General Settings",
    key: "general_settings",
    disableIcon: icons.person,
    activeIcon: icons.selectedPerson,
  },
  {
    id: 1,
    label: "Notification Settings",
    key: "notification_settings",
    disableIcon: icons.bell,
    activeIcon: icons.selectedBell,
  },
  {
    id: 2,
    label: "Trading Settings",
    key: "trading_settings",
    disableIcon: icons.state,
    activeIcon: icons.selectedState,
  },
];

export const settingsMenuOptions = (getContentKey) => [
  {
    id: 0,
    label: getContentKey("general_settings"),
    key: "general_settings",
    disableIcon: icons.person,
    activeIcon: icons.selectedPerson,
  },
  {
    type: "divider",
  },
  {
    id: 1,
    label: getContentKey("notification_settings"),
    key: "notification_settings",
    disableIcon: icons.bell,
    activeIcon: icons.selectedBell,
  },
  {
    type: "divider",
  },
  {
    id: 2,
    label: getContentKey("trading_settings"),
    key: "trading_settings",
    disableIcon: icons.state,
    activeIcon: icons.selectedState,
  },
];

export const reportMenu = [
  {
    id: 0,
    label: "Account statements",
    key: "account_statement",
    disableIcon: icons.statement,
    activeIcon: icons.selectedStatement,
  },
  {
    id: 1,
    label: "Investment reports",
    key: "investment_report",
    disableIcon: icons.investment,
    activeIcon: icons.selectedInvestment,
  },
];

export const reportMenuOptions = (getContentKey) => [
  {
    id: 0,
    label: getContentKey("account_statement"),
    key: "account_statement",
    disableIcon: icons.person,
    activeIcon: icons.selectedPerson,
  },
  {
    type: "divider",
  },
  {
    id: 1,
    label: getContentKey("investment_report"),
    key: "investment_report",
    disableIcon: icons.bell,
    activeIcon: icons.selectedBell,
  },
];

export const fundsMenu = [
  {
    id: 0,
    label: "Subscribe",
    key: "subscribe",
    disableIcon: icons.plus,
    activeIcon: icons.plusSelected,
  },
  {
    id: 1,
    label: "Redeem",
    key: "redeem",
    disableIcon: icons.download,
    activeIcon: icons.downloadSelected,
  },
  {
    id: 2,
    label: "Switch",
    key: "switch",
    disableIcon: icons.roundArrow,
    activeIcon: icons.roundArrowSelected,
  },
  {
    id: 3,
    label: "Transactions",
    key: "transactions",
    disableIcon: icons.slip,
    activeIcon: icons.slipSelected,
  },
];

export const fundsMenuOptions = (getContentKey) => [
  {
    id: 0,
    label: getContentKey("subscribe"),
    key: "subscribe",
    disableIcon: icons.plus,
    activeIcon: icons.plusSelected,
  },
  {
    type: "divider",
  },
  {
    id: 1,
    label: getContentKey("redeem"),
    key: "redeem",
    disableIcon: icons.download,
    activeIcon: icons.downloadSelected,
  },
  {
    type: "divider",
  },
  {
    id: 2,
    label: getContentKey("switch"),
    key: "switch",
    disableIcon: icons.roundArrow,
    activeIcon: icons.roundArrowSelected,
  },
  {
    type: "divider",
  },
  {
    id: 3,
    label: getContentKey("transactions"),
    key: "transactions",
    disableIcon: icons.slip,
    activeIcon: icons.slipSelected,
  },
];

export const transferMenu = [
  {
    id: 0,
    label: "Transfer",
    key: "transfer",
    disableIcon: icons.plus,
    activeIcon: icons.plusSelected,
  },
  {
    id: 1,
    label: "Beneficiaries",
    key: "beneficiaries",
    disableIcon: icons.download,
    activeIcon: icons.downloadSelected,
  },
];

export const transferMenuOptions = (getContentKey) => [
  {
    id: 0,
    label: getContentKey("transfer"),
    key: "transfer",
    disableIcon: icons.plus,
    activeIcon: icons.plusSelected,
  },
  {
    type: "divider",
  },
  {
    id: 1,
    label: getContentKey("beneficiaries"),
    key: "beneficiaries",
    disableIcon: icons.download,
    activeIcon: icons.downloadSelected,
  },
];

export const tradingMenu = [
  {
    id: 0,
    label: "Overview",
    key: "overview",
    disableIcon: icons.state,
    activeIcon: icons.stateSelected,
  },
  {
    id: 1,
    label: "Sub-accounts",
    key: "sub_accounts",
    disableIcon: icons.twoPerson,
    activeIcon: icons.twoPersonSelected,
  },
  {
    id: 2,
    label: "Market",
    key: "market",
    disableIcon: icons.monitorGraph,
    activeIcon: icons.monitorGraphSelected,
  },
  {
    id: 3,
    label: "Order list",
    key: "order_list",
    disableIcon: icons.ordersList,
    activeIcon: icons.orderListSelected,
  },
  {
    id: 4,
    label: "Tadawulaty",
    key: "tadawulaty",
    disableIcon: icons.letterT,
    activeIcon: icons.letterTSelected,
  },
];

export const tradingMenuOptions = (getContentKey) => [
  {
    id: 0,
    label: getContentKey("overview"),
    key: "overview",
    disableIcon: icons.plus,
    activeIcon: icons.plusSelected,
  },
  {
    type: "divider",
  },
  {
    id: 1,
    label: getContentKey("sub_accounts"),
    key: "sub_accounts",
    disableIcon: icons.download,
    activeIcon: icons.downloadSelected,
  },
  {
    type: "divider",
  },
  {
    id: 2,
    label: getContentKey("market"),
    key: "market",
    disableIcon: icons.download,
    activeIcon: icons.downloadSelected,
  },
  {
    type: "divider",
  },
  {
    id: 3,
    label: getContentKey("order_list"),
    key: "order_list",
    disableIcon: icons.download,
    activeIcon: icons.downloadSelected,
  },
  {
    type: "divider",
  },
  {
    id: 4,
    label: getContentKey("tadawulaty"),
    key: "tadawulaty",
    disableIcon: icons.download,
    activeIcon: icons.downloadSelected,
  },
];

// dropdown options

export const languageDropdown = (getContentKey) => [
  {
    key: "select",
    label: getContentKey("select_language"),
  },
  {
    key: 1,
    label: "English",
  },
  {
    key: 2,
    label: "Arabic",
  },
];

export const positionOptions = [
  {
    key: "bottom-right",
    label: "Bottom - right",
  },
  {
    key: "bottom-left",
    label: "Bottom - left",
  },
  {
    key: "top-right",
    label: "Top - right",
  },
  {
    key: "Top-left",
    label: "top - left",
  },
];

export const orderSideOptions = [
  {
    key: "buy",
    label: "Buy",
  },
  {
    key: "sell",
    label: "Sell",
  },
];

export const goodTillOptions = [
  {
    key: "day",
    label: "Day",
  },
  {
    key: "night",
    label: "Night",
  },
];

export const buyModeOptions = [
  {
    key: "limit",
    label: "Limit order",
  },
];

export const fillTermsOptions = [
  {
    key: "market-default",
    label: "Market default",
  },
];

export const sellModeOptions = [
  {
    key: "stop-market-sell",
    label: "Stop market sell",
  },
];

export const portfolioOptions = [
  {
    key: "regular-dfm",
    label: "1 - Regular DFM",
  },
];

export const cashAccountOptionds = [
  {
    key: "0671127728901",
    label: "0671127728901",
  },
];

export const dropdownOptions = [
  {
    key: 0,
    label: "Option 1",
  },
  {
    key: 1,
    label: "Option 2",
  },
  {
    key: 2,
    label: "Option 3",
  },
];

export const transactionsFilterOptions = (getContentKey) => [
  {
    key: 0,
    label: getContentKey("all"),
  },
  {
    key: 1,
    label: getContentKey("subscription"),
  },
  {
    key: 2,
    label: getContentKey("redemption"),
  },
  {
    key: 3,
    label: getContentKey("switch"),
  },
];

export const monthsOptions = (getContentKey) => [
  {
    key: 0,
    label: getContentKey("select_month"),
  },
  {
    key: 1,
    label: getContentKey("jan"),
  },
  {
    key: 2,
    label: getContentKey("feb"),
  },
  {
    key: 3,
    label: getContentKey("march"),
  },
  {
    key: 4,
    label: getContentKey("april"),
  },
  {
    key: 5,
    label: getContentKey("may"),
  },
  {
    key: 6,
    label: getContentKey("june"),
  },
  {
    key: 7,
    label: getContentKey("july"),
  },
  {
    key: 8,
    label: getContentKey("aug"),
  },
  {
    key: 9,
    label: getContentKey("sep"),
  },
  {
    key: 10,
    label: getContentKey("oct"),
  },
  {
    key: 11,
    label: getContentKey("nov"),
  },
  {
    key: 12,
    label: getContentKey("dec"),
  },
];

export const emailOptions = [
  {
    key: 0,
    label: "Select recipient",
  },
  {
    key: 1,
    label: "John",
  },
  {
    key: 2,
    label: "David",
  },
];

// tables data

export const overViewTableData = [
  {
    id: 0,
    col1: "",
    col2: "Balance",
    col3: "% of net assets",
  },
  {
    id: 1,
    col1: "Managed investments",
    col2: "176,865,715.45",
    col3: 95,
  },
  {
    id: 2,
    col1: "Brokerage",
    col2: "34,400.00",
    col3: 5,
  },
  {
    id: 3,
    col1: "Total",
    col2: "186,681,514.45",
    col3: "100%",
  },
];

export const brokerageHoldingsColumns = (TextWeight, getContentKey) => [
  {
    title: () => (
      <Text
        text={getContentKey("current_investment")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "fundName",
    key: "fundName",
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={getContentKey("quantity")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "quantity",
    key: "quantity",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={"text-right"} />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("total_cost")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "totalCost",
    key: "totalCost",
    render: (text) => (
      <Text
        text={text?.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("avg_cost")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "averageCost",
    key: "averageCost",
    render: (text) => (
      <Text
        text={text?.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("market_price")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "marketPrice",
    key: "marketPrice",
    render: (text) => (
      <Text
        text={text?.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("market_value")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "marketValueSar",
    key: "marketValueSar",
    render: (text) => (
      <Text
        text={text?.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("gain_loss")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "a",
    key: "a",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={"text-right"} />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("validation_date")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "valuationDate",
    key: "valuationDate",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={"text-right"} />
    ),
  },
];

export const cashBalanceColumns = (TextWeight, getContentKey) => [
  {
    title: () => (
      <Text
        text={getContentKey("investment_portfolio")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "fundName",
    key: "fundName",
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={getContentKey("currency")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "currency",
    key: "currency",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={"text-right"} />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("value")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "value",
    key: "value",
    render: (text) => (
      <Text
        text={text?.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("value_sar")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "valueSar",
    key: "valueSar",
    render: (text) => (
      <Text
        text={text?.toLocaleString()}
        weight={TextWeight.light}
        className={"text-right"}
      />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("allocation_percentage")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={"text-right"}
      />
    ),
    dataIndex: "allocationPercentage",
    key: "allocationPercentage",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={"text-right"} />
    ),
  },
];

export const transactionsColumns = (TextWeight, getContentKey) => [
  {
    title: () => <ReactSVG src={icons.settings} />,
    dataIndex: "id",
    key: "id",
    render: () => <ReactSVG src={icons.dots} className="ms-1" />,
    width: 10,
  },
  {
    title: () => (
      <Text
        text={getContentKey("table_id")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "id",
    key: "id",
    sorter: (a, b) => a.id - b.id,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={getContentKey("type")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "type",
    key: "type",
    sorter: (a, b) => a.type.length - b.type.length,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={getContentKey("fund")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "fund",
    key: "fund",
    sorter: (a, b) => a.fund.length - b.fund.length,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={getContentKey("amount")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "amount",
    key: "amount",
    sorter: (a, b) => a.amount - b.amount,
    render: (text) => (
      <Text text={text?.toLocaleString()} weight={TextWeight.light} />
    ),
  },
  {
    title: () => (
      <Text
        text={getContentKey("date")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "date",
    key: "date",
    sorter: (a, b) => {
      const dateA = new Date(a.date.split("/").reverse().join("/"));
      const dateB = new Date(b.date.split("/").reverse().join("/"));
      return dateA - dateB;
    },
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={getContentKey("status")}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.status.length - b.status.length,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
];

export const transactionsData = [
  {
    key: 0,
    id: 64147714671541,
    type: "Subscription",
    fund: "Tadawul 1",
    amount: 2000,
    date: "18/06/2019",
    status: "Submitted",
  },
  {
    key: 1,
    id: 98729371837361,
    type: "Redemption",
    fund: "Tadawul 2",
    amount: 230,
    date: "23/05/2018",
    status: "Completed",
  },
  {
    key: 2,
    id: 51938931801018,
    type: "Switch",
    fund: "Tadawul 3",
    amount: 100,
    date: "28/04/2018",
    status: "Completed",
  },
];

export const transferColumns = (TextWeight) => [
  {
    title: () => <ReactSVG src={icons.settings} />,
    dataIndex: "settings",
    key: "settings",
    render: () => <ReactSVG src={icons.dots} className="ms-1" />,
    width: 10,
  },
  {
    title: () => (
      <Text
        text={"ID"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "id",
    key: "id",
    sorter: (a, b) => a.id - b.id,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={"Transaction number"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "transactionNumber",
    key: "transactionNumber",
    sorter: (a, b) => a.transactionNumber - b.transactionNumber,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={"Transaction date"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "creationDate",
    key: "creationDate",
    sorter: (a, b) => {
      const dateA = new Date(a.date.split("/").reverse().join("/"));
      const dateB = new Date(b.date.split("/").reverse().join("/"));
      return dateA - dateB;
    },
    render: (text) => {
      const date = new Date(text);
      return <Text text={date.toLocaleDateString()} weight={TextWeight.light} />;
    },
  },
  {
    title: () => (
      <Text
        text={"amount"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "amount",
    key: "amount",
    sorter: (a, b) => a.amount - b.amount,
    render: (text) => (
      <Text text={text?.toLocaleString()} weight={TextWeight.light} />
    ),
  },
  {
    title: () => (
      <Text
        text={"From account"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "accountNumber",
    key: "accountNumber",
    sorter: (a, b) => a.accountNumber - b.accountNumber,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={"To account (IBAN)"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "toaccount",
    key: "toaccount",
    sorter: (a, b) => a.status.length - b.status.length,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={"Amount"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "amount",
    key: "amount",
    sorter: (a, b) => a.amount - b.amount,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={"Amount Currency"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "currency",
    key: "currency",
    sorter: (a, b) => a.currency.length - b.currency.length,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={"Status"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.status.length - b.status.length,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
  {
    title: () => (
      <Text
        text={"Comments"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
      />
    ),
    dataIndex: "rejectionReason",
    key: "rejectionReason",
    sorter: (a, b) => a.rejectionReason.length - b.rejectionReason.length,
    render: (text) => <Text text={text} weight={TextWeight.light} />,
  },
];

export const transferData = [];

export const beneficiaryColumns = (TextWeight) => [
  {
    title: () => "",
    dataIndex: "close",
    key: "close",
    render: () => <div className="w-full flex justify-center"><ReactSVG src={icons.tableCross} className="ms-1 " /></div> ,
    width: 100,
    sorter: (a, b) => a.id.length - b.id.length,
  },
  {
    title: () => <Text
    text={"ID"}
    size={fontSizes.h14}
    weight={TextWeight.bold}
    className={'text-right'}
  />,
    dataIndex: "beneficiaryId",
    key: "beneficiaryId",
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'}/>,
    sorter: (a, b) => a.beneficiaryId - b.beneficiaryId,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Name"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "beneficiaryName",
    key: "beneficiaryName",
    sorter: (a, b) => a.beneficiaryName.length - b.beneficiaryName.length,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 160,
  },
  {
    title: () => (
      <Text
        text={"Bank name"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "bankName",
    key: "bankName",
    sorter: (a, b) => a.bankName.length - b.bankName.length,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 130,
  },
  {
    title: () => (
      <Text
        text={"Account Number (IBAN)"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "accountNumber",
    key: "accountNumber",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={'text-right'} />
    ),
    sorter: (a, b) => a.accountNumber.length - b.accountNumber.length,
    width: 220,
  },
  {
    title: () => (
      <Text
        text={"Status"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.status.length - b.status.length,
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={'text-right'} />
    ),
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Creation date"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "creationDate",
    key: "creationDate",
    sorter: (a, b) => {
      const dateA = new Date(a.creationDate.split("/").reverse().join("/"));
      const dateB = new Date(b.dacreationDatete.split("/").reverse().join("/"));
      return dateA - dateB;
    },
    render: (text) => {
      const date = new Date(text);
      return <Text text={date.toLocaleDateString()} weight={TextWeight.light} className={"text-right"} />;
    },
    width: 150,
  },
  {
    title: () => (
      <Text
        text={"Issued request"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "issuedRequest",
    key: "issuedRequest",
    sorter: (a, b) => a.issuedRequest.length - b.issuedRequest.length,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 160,
  },
  {
    title: () => (
      <Text
        text={"Comments"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "comments",
    key: "comments",
    sorter: (a, b) => a.comments.length - b.comments.length,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 150,
  },
];

export const beneficiaryData = [
  {
    close: '',
    id: 1570,
    name: 'Jadwa Investment Co',
    bankName: 'Santander',
    accountNumber: '5A565500000000966860800179',
    status: 'Accepted',
    creationDate: '19/09/2018',
    issuedRequest: 'View request',
    comments: 'Comment 1',
  }
];

export const portfolioColumns = (TextWeight) => [
  {
    title: () => <ReactSVG src={icons.settings} className="ms-1 " />,
    dataIndex: "close",
    key: "close",
    render: () => <ReactSVG src={icons.dots} className="ms-2" />,
    width: 35,
    // sorter: (a, b) => a.id.length - b.id.length,
  },
  {
    title: () => <Text
    text={"Symbol Name"}
    size={fontSizes.h14}
    weight={TextWeight.bold}
    className={'text-right'}
  />,
    dataIndex: "symbol_name",
    key: "symbol_name",
    render: (text) => <Text text={text} weight={TextWeight.light}/>,
    sorter: (a, b) => a.symbol_name.length - b.symbol_name.length,
    width: 140,
  },
  {
    title: () => (
      <Text
        text={"ID"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "id",
    key: "id",
    sorter: (a, b) => a.id - b.id,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Quantity"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "quantity",
    key: "quantity",
    sorter: (a, b) => a.quantity - b.quantity,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Pledged"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "pledged",
    key: "pledged",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={'text-right'} />
    ),
    sorter: (a, b) => a.pledged.length - b.pledged.length,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Available"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "available",
    key: "available",
    sorter: (a, b) => a.available - b.available,
    render: (text) => (
      <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />
    ),
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"AvgCost"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "avgCost",
    key: "avgCost",
    sorter: (a, b) => a.avgCost - b.avgCost,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Cost"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "cost",
    key: "cost",
    sorter: (a, b) => a.cost.length - b.cost.length,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Outstanding Sell Units"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "outstanding_sell_units",
    key: "outstanding_sell_units",
    sorter: (a, b) => a.outstanding_sell_units - b.outstanding_sell_units,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 140,
  },
  {
    title: () => (
      <Text
        text={"Outstanding Buy Units"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "outstanding_buy_units",
    key: "outstanding_buy_units",
    sorter: (a, b) => a.outstanding_buy_units - b.outstanding_buy_units,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 140,
  },
];

export const portfolioData = [
  {
    close: '',
    id: 1570,
    symbol_name: 'Ryad Bank',
    quantity: 3999,
    pledged: 0.00,
    available: 3979,
    avgCost: 373.87,
    cost: 1495125.53,
    outstanding_sell_units: 20,
    outstanding_buy_units: 0.00,
  }
];

export const orderBasketColumns = (TextWeight) => [
  {
    title: () => <ReactSVG src={icons.settings} className="w-2 h-2" />,
    dataIndex: "close",
    key: "close",
    render: () => <ReactSVG src={icons.dots} className="ms-2" />,
    width: 35,
    // sorter: (a, b) => a.id.length - b.id.length,
  },
  {
    title: () => <Text
    text={"Basket Order No."}
    size={fontSizes.h14}
    weight={TextWeight.bold}
    className={"leading-4"}
  />,
    dataIndex: "order_no",
    key: "order_no",
    render: (text) => <Text text={text} weight={TextWeight.light}/>,
    sorter: (a, b) => a.order_no.length - b.order_no.length,
    width: 140,
  },
  {
    title: () => (
      <Text
        text={"Symbol ID"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "id",
    key: "id",
    sorter: (a, b) => a.id - b.id,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Symbol Short Name"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "symbol_short_name",
    key: "symbol_short_name",
    sorter: (a, b) => a.symbol_short_name - b.symbol_short_name,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Symbol Name"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "Symbol_name",
    key: "Symbol_name",
    render: (text) => (
      <Text text={text} weight={TextWeight.light} className={'text-right'} />
    ),
    sorter: (a, b) => a.Symbol_name.length - b.Symbol_name.length,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Quantity"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "quantity",
    key: "quantity",
    sorter: (a, b) => a.quantity - b.quantity,
    render: (text) => (
      <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />
    ),
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Price"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "price",
    key: "price",
    sorter: (a, b) => a.price - b.price,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"portfolio"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "portfolio",
    key: "portfolio",
    sorter: (a, b) => a.portfolio.length - b.portfolio.length,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 120,
  },
  {
    title: () => (
      <Text
        text={"Order Side"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "order_side",
    key: "order_side",
    sorter: (a, b) => a.order_side - b.order_side,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Order Type"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "order_type",
    key: "order_type",
    sorter: (a, b) => a.order_type - b.order_type,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Market"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "market",
    key: "market",
    sorter: (a, b) => a.market - b.market,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
];

export const orderBasketData = [
  {
    close: '',
    order_no: 2,
    id: 1570,
    symbol_short_name: 3999,
    Symbol_name: 0.00,
    quantity: 3979,
    price: 373.87,
    portfolio: 1495125.53,
    order_side: 20,
    order_type: 0.00,
    market: 0.00,
  }
];

export const watchListColumns = (TextWeight) => [
  {
    title: () => <ReactSVG src={icons.settings} className="ms-1 " />,
    dataIndex: "setting",
    key: "setting",
    render: () => <ReactSVG src={icons.dots} className="ms-2" />,
    width: 30,
  },
  {
    title: () => <Text
    text={"Symbol Name"}
    size={fontSizes.h14}
    weight={TextWeight.bold}
  />,
    dataIndex: "symbolName",
    key: "symbolName",
    render: (text) => <Text text={text} weight={TextWeight.light}/>,
    sorter: (a, b) => a.symbolName.length - b.symbolName.length,
    width: 150,
  },
  {
    title: () => (
      <Text
        text={"Bid Price"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "bidPrice",
    key: "bidPrice",
    sorter: (a, b) => a.bidPrice - b.bidPrice,
    render: (text) => { 
      return {
        props: {
          style: { background: "#67C370" },
          className: "no-hover-bg"
        },
        children: <Text text={text} weight={TextWeight.light} className={'text-right pe-3'} />
      };
    },
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"ID"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "id",
    key: "id",
    sorter: (a, b) => a.id - b.id,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right pe-3'} />,
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"Offer Price"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "offerPrice",
    key: "offerPrice",
    render: (text) => {
      return {
        props: {
          style: { background: "#EA424E" },
          className: "no-hover-bg"
        },
        children: <Text text={text} weight={TextWeight.light} color="text-white" className={'text-right pe-3'} />
      };
    },
    sorter: (a, b) => a.offerPrice - b.offerPrice,
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"Last Price"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "lastPrice",
    key: "lastPrice",
    sorter: (a, b) => a.lastPrice - b.lastPrice,
    render: (text) => (
      <Text text={text} weight={TextWeight.light} color="text-light-blue" className={'text-right pe-3'} />
    ),
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"chg"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "chg",
    key: "chg",
    sorter: (a, b) => a.chg - b.chg,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right pe-3'} />,
    width: 90,
  },

  {
    title: () => (
      <Text
        text={"Offer qty"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "offerQty",
    key: "offerQty",
    sorter: (a, b) => a.offerQty - b.offerQty,
    render: (text) => {
    return {
      props: {
        style: { background: "#EA424E" },
        className: "no-hover-bg "
      },
      children: <Text text={text} weight={TextWeight.light} color="text-white" className={'text-right pe-3'} />
    };
    },
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"chg %"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "chgPercent",
    key: "chgPercent",
    sorter: (a, b) => a.chgPercent - b.chgPercent,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right pe-3'} />,
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"Prev Close"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "prevClose",
    key: "prevClose",
    sorter: (a, b) => a.prevClose - b.prevClose,
    render: (text) => <Text text={text} weight={TextWeight.light} color="text-light-blue" className={'text-right pe-3'} />,
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"No. Of Trades"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "noOfTrades",
    key: "noOfTrades",
    sorter: (a, b) => a.noOfTrades - b.noOfTrades,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right pe-3'} />,
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"Last Volume"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right'}
      />
    ),
    dataIndex: "lastVolumn",
    key: "lastVolumn",
    sorter: (a, b) => a.lastVolumn - b.lastVolumn,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right pe-3'} />,
    width: 90,
  },
];

export const watchListData = [
  {
    symbolName: "Ryad Bank",
    bidPrice: 0.00,
    id: 1010,
    offerPrice: 0.00,
    lastPrice: 0.00,
    chg: 0.00,
    offerQty: 0,
    chgPercent: 0.00,
    prevClose: 0.00,
    noOfTrades: 0,
    lastVolumn: 0,
  },
  {
    symbolName: "Ryad Bank",
    bidPrice: 0.00,
    id: 1010,
    offerPrice: 0.00,
    lastPrice: 0.00,
    chg: 0.00,
    offerQty: 0,
    chgPercent: 0.00,
    prevClose: 0.00,
    noOfTrades: 0,
    lastVolumn: 0,
  },
  {
    symbolName: "Ryad Bank",
    bidPrice: 0.00,
    id: 1010,
    offerPrice: 0.00,
    lastPrice: 0.00,
    chg: 0.00,
    offerQty: 0,
    chgPercent: 0.00,
    prevClose: 0.00,
    noOfTrades: 0,
    lastVolumn: 0,
  },
  {
    symbolName: "Ryad Bank",
    bidPrice: 0.00,
    id: 1010,
    offerPrice: 0.00,
    lastPrice: 0.00,
    chg: 0.00,
    offerQty: 0,
    chgPercent: 0.00,
    prevClose: 0.00,
    noOfTrades: 0,
    lastVolumn: 0,
  },
  {
    symbolName: "Ryad Bank",
    bidPrice: 0.00,
    id: 1010,
    offerPrice: 0.00,
    lastPrice: 0.00,
    chg: 0.00,
    offerQty: 0,
    chgPercent: 0.00,
    prevClose: 0.00,
    noOfTrades: 0,
    lastVolumn: 0,
  },
  {
    symbolName: "Ryad Bank",
    bidPrice: 0.00,
    id: 1010,
    offerPrice: 0.00,
    lastPrice: 0.00,
    chg: 0.00,
    offerQty: 0,
    chgPercent: 0.00,
    prevClose: 0.00,
    noOfTrades: 0,
    lastVolumn: 0,
  },
  {
    symbolName: "Ryad Bank",
    bidPrice: 0.00,
    id: 1010,
    offerPrice: 0.00,
    lastPrice: 0.00,
    chg: 0.00,
    offerQty: 0,
    chgPercent: 0.00,
    prevClose: 0.00,
    noOfTrades: 0,
    lastVolumn: 0,
  },
  {
    symbolName: "Ryad Bank",
    bidPrice: 0.00,
    id: 1010,
    offerPrice: 0.00,
    lastPrice: 0.00,
    chg: 0.00,
    offerQty: 0,
    chgPercent: 0.00,
    prevClose: 0.00,
    noOfTrades: 0,
    lastVolumn: 0,
  },
];

export const stockPositionColumns = (TextWeight) => [
  {
    title: () => <ReactSVG src={icons.settings} className="w-2 h-2" />,
    dataIndex: "close",
    key: "close",
    render: () => <ReactSVG src={icons.dots} className="ms-2" />,
    width: 35,
    // sorter: (a, b) => a.id.length - b.id.length,
  },
  {
    title: () => <Text
    text={"Symbol Name"}
    size={fontSizes.h14}
    weight={TextWeight.bold}
    className={"leading-4"}
  />,
    dataIndex: "symbolName",
    key: "symbolName",
    render: (text) => <Text text={text} weight={TextWeight.light}/>,
    sorter: (a, b) => a.symbolName.length - b.symbolName.length,
    width: 140,
  },
  {
    title: () => <Text
    text={"ID"}
    size={fontSizes.h14}
    weight={TextWeight.bold}
    className={"text-right leading-4"}
  />,
    dataIndex: "id",
    key: "id",
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'}/>,
    sorter: (a, b) => a.id.length - b.id.length,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Quantity"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "quantity",
    key: "quantity",
    sorter: (a, b) => a.quantity - b.quantity,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Pledged"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "pledged",
    key: "pledged",
    sorter: (a, b) => a.pledged - b.pledged,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Available"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "available",
    key: "available",
    render: (text) => (
      <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />
    ),
    sorter: (a, b) => a.available - b.available,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"AvgCost"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "avgCost",
    key: "avgCost",
    sorter: (a, b) => a.avgCost - b.avgCost,
    render: (text) => (
      <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />
    ),
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Cost"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "cost",
    key: "cost",
    sorter: (a, b) => a.cost - b.cost,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"Outstanding Sell Units"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "outstandingSellUnits",
    key: "outstandingSellUnits",
    sorter: (a, b) => a.outstandingSellUnits.length - b.outstandingSellUnits.length,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 120,
  },
  {
    title: () => (
      <Text
        text={"Outstanding Buy Units"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "outstandingBuyUnits",
    key: "outstandingBuyUnits",
    sorter: (a, b) => a.outstandingBuyUnits - b.outstandingBuyUnits,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Market Value"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "marketValue",
    key: "marketValue",
    sorter: (a, b) => a.marketValue - b.marketValue,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Evaluation Price"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "evaluationPrice",
    key: "evaluationPrice",
    sorter: (a, b) => a.evaluationPrice - b.evaluationPrice,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Unrlzd G/L"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "unrlzd",
    key: "unrlzd",
    sorter: (a, b) => a.unrlzd - b.unrlzd,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right text-red-500'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Currency"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "currency",
    key: "currency",
    sorter: (a, b) => a.currency.length - b.currency.length,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
];

export const stockPositionData = [
  {
    close: '',
    symbolName: 'SABIC',
    id: 1570,
    quantity: 3999,
    pledged: 0.00,
    available: 3979,
    avgCost: 373.87,
    cost: 1495125.53,
    outstandingSellUnits: 20,
    outstandingBuyUnits: 422,
    marketValue: 483879.00,
    evaluationPrice: 121.00,
    unrlzd: -1011246.53,
    currency: 'SAR'
  }
];

export const orderDetailColumns = (TextWeight) => [
  {
    title: () => <ReactSVG src={icons.settings} className="w-2 h-2" />,
    dataIndex: "close",
    key: "close",
    render: () => <ReactSVG src={icons.dots} className="ms-2" />,
    width: 35,
    // sorter: (a, b) => a.id.length - b.id.length,
  },
  {
    title: () => <Text
    text={"Basket Order No."}
    size={fontSizes.h14}
    weight={TextWeight.bold}
    className={"leading-4"}
  />,
    dataIndex: "orderNo",
    key: "orderNo",
    render: (text) => <Text text={text} weight={TextWeight.light}/>,
    sorter: (a, b) => a.orderNo - b.orderNo,
    width: 140,
  },
  {
    title: () => <Text
    text={"Symbol ID"}
    size={fontSizes.h14}
    weight={TextWeight.bold}
    className={"text-right leading-4"}
  />,
    dataIndex: "id",
    key: "id",
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'}/>,
    sorter: (a, b) => a.id - b.id,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Symbol Name"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "symbolName",
    key: "symbolName",
    sorter: (a, b) => a.symbolName.length - b.symbolName.length,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Side"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "side",
    key: "side",
    sorter: (a, b) => a.side.length - b.side.length,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={`text-right ${text == "Buy" ? "text-green-500" : "text-red-500"}`} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Quantity"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "quantity",
    key: "quantity",
    render: (text) => (
      <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />
    ),
    sorter: (a, b) => a.quantity - b.quantity,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Price"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "price",
    key: "price",
    sorter: (a, b) => a.price - b.price,
    render: (text) => (
      <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />
    ),
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Order Type"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "orderType",
    key: "orderType",
    sorter: (a, b) => a.orderType.length - b.orderType.length,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"Order Status"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "orderStatus",
    key: "orderStatus",
    sorter: (a, b) => a.orderStatus.length - b.orderStatus.length,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 120,
  },
  {
    title: () => (
      <Text
        text={"portfolio"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "portfolio",
    key: "portfolio",
    sorter: (a, b) => a.portfolio.length - b.portfolio.length,
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Currency"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "currency",
    key: "currency",
    sorter: (a, b) => a.currency.length - b.currency.length,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Created Date"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "createdDate",
    key: "createdDate",
    sorter: (a, b) => a.createdDate - b.createdDate,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Expired Date"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "expiredDate",
    key: "expiredDate",
    sorter: (a, b) => a.expiredDate.length - b.expiredDate.length,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
];

export const orderDetailData = [
  {
    close: '',
    orderNo: 8000866041,
    id: 1020,
    symbolName: "Al-Jazira Bank",
    side: 'Buy',
    quantity: 12,
    price: 16.50,
    orderType: "Limit order (Buy)",
    orderStatus: "Queued",
    portfolio: "1 - TADAWADUL1",
    currency: "SAR",
    createdDate: "14/09/2020",
    expiredDate: "14/09/2020",
  },
  {
    close: '',
    orderNo: 8000866041,
    id: 1020,
    symbolName: "Al-Jazira Bank",
    side: 'Sell',
    quantity: 12,
    price: 16.50,
    orderType: "Limit order (Buy)",
    orderStatus: "Queued",
    portfolio: "1 - TADAWADUL1",
    currency: "SAR",
    createdDate: "14/09/2020",
    expiredDate: "14/09/2020",
  },
  {
    close: '',
    orderNo: 8000866041,
    id: 1020,
    symbolName: "Al-Jazira Bank",
    side: 'Buy',
    quantity: 12,
    price: 16.50,
    orderType: "Limit order (Buy)",
    orderStatus: "Queued",
    portfolio: "1 - TADAWADUL1",
    currency: "SAR",
    createdDate: "14/09/2020",
    expiredDate: "14/09/2020",
  },
  {
    close: '',
    orderNo: 8000866041,
    id: 1020,
    symbolName: "Al-Jazira Bank",
    side: 'Sell',
    quantity: 12,
    price: 16.50,
    orderType: "Limit order (Buy)",
    orderStatus: "Queued",
    portfolio: "1 - TADAWADUL1",
    currency: "SAR",
    createdDate: "14/09/2020",
    expiredDate: "14/09/2020",
  },
  {
    close: '',
    orderNo: 8000866041,
    id: 1020,
    symbolName: "Al-Jazira Bank",
    side: 'Buy',
    quantity: 12,
    price: 16.50,
    orderType: "Limit order (Buy)",
    orderStatus: "Queued",
    portfolio: "1 - TADAWADUL1",
    currency: "SAR",
    createdDate: "14/09/2020",
    expiredDate: "14/09/2020",
  },
  {
    close: '',
    orderNo: 8000866041,
    id: 1020,
    symbolName: "Al-Jazira Bank",
    side: 'Buy',
    quantity: 12,
    price: 16.50,
    orderType: "Limit order (Buy)",
    orderStatus: "Queued",
    portfolio: "1 - TADAWADUL1",
    currency: "SAR",
    createdDate: "14/09/2020",
    expiredDate: "14/09/2020",
  },
];

export const gainLossColumns = (TextWeight) => [
  {
    title: () => <ReactSVG src={icons.settings} className="w-2 h-2" />,
    dataIndex: "close",
    key: "close",
    render: () => <ReactSVG src={icons.dots} className="ms-2" />,
    width: 35,
    // sorter: (a, b) => a.id.length - b.id.length,
  },
  {
    title: () => <Text
    text={"Symbol Name"}
    size={fontSizes.h14}
    weight={TextWeight.bold}
    className={"leading-4"}
  />,
    dataIndex: "name",
    key: "name",
    render: (text) => <Text text={text} weight={TextWeight.light}/>,
    sorter: (a, b) => a.name.length - b.name.length,
    width: 140,
  },
  {
    title: () => <Text
    text={"ID"}
    size={fontSizes.h14}
    weight={TextWeight.bold}
    className={"text-right leading-4"}
  />,
    dataIndex: "id",
    key: "id",
    render: (text) => <Text text={text} weight={TextWeight.light} className={'text-right'}/>,
    sorter: (a, b) => a.id - b.id,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Last Price"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "lastPrice",
    key: "lastPrice",
    sorter: (a, b) => a.lastPrice - b.lastPrice,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Chg Dir"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "chgDir",
    key: "chgDir",
    sorter: (a, b) => a.chgDir - b.chgDir,
    render: (text) => <div className="flex justify-end"><ReactSVG src={icons.profitArrow} /></div>,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Chg"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "chg",
    key: "chg",
    render: (text) => (
      <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right text-green-500'} />
    ),
    sorter: (a, b) => a.chg - b.chg,
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"Chg%"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "chgPercent",
    key: "chgPercent",
    sorter: (a, b) => a.chgPercent - b.chgPercent,
    render: (text) => (
      <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />
    ),
    width: 100,
  },
  {
    title: () => (
      <Text
        text={"T.Volume"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "TVolume",
    key: "TVolume",
    sorter: (a, b) => a.TVolume - b.TVolume,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 90,
  },
  {
    title: () => (
      <Text
        text={"Turnover"}
        size={fontSizes.h14}
        weight={TextWeight.bold}
        className={'text-right leading-4'}
      />
    ),
    dataIndex: "turnover",
    key: "turnover",
    sorter: (a, b) => a.turnover - b.turnover,
    render: (text) => <Text text={text?.toLocaleString()} weight={TextWeight.light} className={'text-right'} />,
    width: 120,
  },
];

export const gainLossData = [
  {
    close: '',
    name: "Al Sorayal Group",
    id: 2010,
    lastPrice: 14.50,
    chg: 0.66,
    chgPercent: 0,
    TVolumn: 0,
    turnover: 0,
  },
];

// trading data

export const priceSpectrum = [
  {
    bid: '90 (1)',
    price: 8.4,
    offer: '90 (1)',
    bidPercentage: 10,
    offerPercentage: 0,
  },
  {
    bid: '50 (1)',
    price: 8.35,
    offer: '50 (1)',
    bidPercentage: 10,
    offerPercentage: 40,
  },
  {
    bid: '2,000 (1)',
    price: 8.33,
    offer: '2,000 (1)',
    bidPercentage: 0,
    offerPercentage: 0,
  },
  {
    bid: '56 (1)',
    price: 8.31,
    offer: '56 (1)',
    bidPercentage: 5,
    offerPercentage: 15,
  },
  {
    bid: '21,090 (3)',
    price: 8.3,
    offer: '21,090 (3)',
    bidPercentage: 30,
    offerPercentage: 20,
  },
  {
    bid: '25 (1)',
    price: 8.28,
    offer: '25 (1)',
    bidPercentage: 18,
    offerPercentage: 38,
  },
  {
    bid: '319 (3)',
    price: 8.25,
    offer: '319 (3)',
    bidPercentage: 60,
    offerPercentage: 20,
  },
  {
    bid: '26,695 (4)',
    price: 8.2,
    offer: '26,695 (4)',
    bidPercentage: 8,
    offerPercentage: 70,
  },
  {
    bid: '56 (1)',
    price: 8.18,
    offer: '56 (1)',
    bidPercentage: 35,
    offerPercentage: 10,
  },
];

export const indicesData = [
  {
    name: "Tasi",
    desc: "Tadawul All Shares Index",
    price: 7526.54,
    isDown: true,
    percent: "-135.63 (-1.77%)",
  },
  {
    name: "Tasi",
    desc: "Tadawul All Shares Index",
    price: 7526.54,
    isDown: true,
    percent: "-135.63 (-1.77%)",
  },
  {
    name: "Tasi",
    desc: "Tadawul All Shares Index",
    price: 7526.54,
    isDown: false,
    percent: "-135.63 (-1.77%)",
  },
  {
    name: "Tasi",
    desc: "Tadawul All Shares Index",
    price: 7526.54,
    isDown: true,
    percent: "-135.63 (-1.77%)",
  },
  {
    name: "Tasi",
    desc: "Tadawul All Shares Index",
    price: 7526.54,
    isDown: true,
    percent: "-135.63 (-1.77%)",
  },
  {
    name: "Tasi",
    desc: "Tadawul All Shares Index",
    price: 7526.54,
    isDown: false,
    percent: "-135.63 (-1.77%)",
  },
  {
    name: "Tasi",
    desc: "Tadawul All Shares Index",
    price: 7526.54,
    isDown: true,
    percent: "-135.63 (-1.77%)",
  },
  {
    name: "Tasi",
    desc: "Tadawul All Shares Index",
    price: 7526.54,
    isDown: true,
    percent: "-135.63 (-1.77%)",
  },
];

export const outstandingOrders = [
  {
    id: 866027,
    orderNo: 1020,
    bank: "Al-Jazira Bank",
    type: "Buy",
    email: "2999@458.00",
    status: "Queued",
  },
  {
    id: 866027,
    orderNo: 1020,
    bank: "Al-Jazira Bank",
    type: "Buy",
    email: "2999@458.00",
    status: "Queued",
  },
  {
    id: 866027,
    orderNo: 1020,
    bank: "Al-Jazira Bank",
    type: "Sell",
    email: "2999@458.00",
    status: "Queued",
  },
  {
    id: 866027,
    orderNo: 1020,
    bank: "Al-Jazira Bank",
    type: "Buy",
    email: "2999@458.00",
    status: "Queued",
  },
  {
    id: 866027,
    orderNo: 1020,
    bank: "Al-Jazira Bank",
    type: "Buy",
    email: "2999@458.00",
    status: "Queued",
  },
  {
    id: 866027,
    orderNo: 1020,
    bank: "Al-Jazira Bank",
    type: "Buy",
    email: "2999@458.00",
    status: "Queued",
  },
  {
    id: 866027,
    orderNo: 1020,
    bank: "Al-Jazira Bank",
    type: "Sell",
    email: "2999@458.00",
    status: "Queued",
  },
  {
    id: 866027,
    orderNo: 1020,
    bank: "Al-Jazira Bank",
    type: "Buy",
    email: "2999@458.00",
    status: "Queued",
  },
];

// tabs data

export const orderDetailsTabs = [
  {
    key: "all",
    label: "All"
  },
  {
    key: "outstanding",
    label: "Outstanding"
  },
  {
    key: "filled",
    label: "Filled"
  },
  {
    key: "cancelled",
    label: "Cancelled/Rejected"
  },
];

export const gainLossTabs = [
  {
    key: "gainers",
    label: "Gainers"
  },
  {
    key: "losers",
    label: "Losers"
  },
];

export const activeSymbolsTabs = [
  {
    key: "value",
    label: "Value"
  },
  {
    key: "volume",
    label: "Volume"
  },
];

// extra data 

export const chartDurationLabel = [
  {
    key: "1d",
    label: "1D",
  },
  {
    key: "1w",
    label: "1W",
  },
  {
    key: "1m",
    label: "1M",
  },
  {
    key: "6m",
    label: "6M",
  },
  {
    key: "1y",
    label: "1Y",
  },
  {
    key: "5y",
    label: "6Y",
  },
];