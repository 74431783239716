import React from "react";
import reportFunctional from "./index.function";
import Sidebar from "../../components/Sidebar";
import { reportMenu } from "../../config/config";
import AccountStatement from "./components/AccountStatement";
import InvestmentReport from "./components/InvestmentReport";

const Report = () => {
  const { selected, onPressMenu } = reportFunctional();

  return (
    <Sidebar
      selected={selected}
      onPressMenu={onPressMenu}
      menuList={reportMenu}
    >
      {
        selected === "account_statement" ?
        <AccountStatement/>
        : <InvestmentReport/>
      }

    </Sidebar>
  );
};

export default Report;
