import React from "react";
import tradingFunctional from "./index.function";
import Sidebar from "../../components/Sidebar";
import { tradingMenu } from "../../config/config";
import TradingOverview from "./components/TradingOverview";
import SubAccounts from "./components/SubAccounts";
import Market from "./components/Market";
import OrderList from "./components/OrderList";
import Tadawulaty from "./components/Tadawulaty";

const Trading = () => {
  const { selected, onPressMenu } = tradingFunctional();

  return (
    <Sidebar
      menuList={tradingMenu}
      selected={selected}
      onPressMenu={onPressMenu}
    >
      {selected == "overview" ? (
        <TradingOverview />
      ) : selected == "sub_accounts" ? (
        <SubAccounts />
      ) : selected == "market" ? (
        <Market />
      ) : selected == "order_list" ? (
        <OrderList />
      ) : selected == "tadawulaty" ? (
        <Tadawulaty />
      ) : null}
    </Sidebar>
  );
};

export default Trading;
