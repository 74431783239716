import React from "react";
import dynamicfunction from "../utils/dynamicfunction";

const ViewReverse = ({ children, className, style, onClick }) => {
  const { isArabic } = dynamicfunction();

  return (
    <div
      className={`flex ${
        isArabic ? "flex-row-reverse" : "flex-row"
      } ${className}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default ViewReverse;
