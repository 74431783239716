import React, { useEffect } from "react";
import ViewReverse from "./ViewReverse";
import { ReactSVG } from "react-svg";
import Text from "./Text";
import { fontSizes } from "../constants/constants";
import dynamicfunction from "../utils/dynamicfunction";
import icons from "../constants/icons";
import ViewItems from "./ViewItems";
import { useSelector } from "react-redux";
import { getContentKeyAccToLang } from "../utils/utils";

const Sidebar = ({ menuList, children, selected, onPressMenu, selectedChild, setSelectedChild }) => {
  const { dashboardContent } = useSelector(state => state.content);
  const { TextWeight, isArabic } = dynamicfunction();

  const getContentKey = (key) => {
    return getContentKeyAccToLang(dashboardContent, key, isArabic);
  };

  return (
    <div className="grid grid-cols-12">
      <div className={`hidden lg:block col-span-3 ${isArabic && "order-last"}`}>
        {menuList.map((item, index) => {
          const isSelected = selected == item.key;
          return (
            <div
              key={index}
              className={`cursor-pointer
              px-6 py-8 
              ${isArabic ? "border-r-4" : "border-l-4"} 
              ${isSelected ? "bg-white" : "bg-transparent"} 
              ${
                isSelected
                  ? isArabic
                    ? "border-r-light-blue"
                    : "border-l-light-blue"
                  : "border-transparent"
              }
              ${
                isSelected
                  ? `${
                      isArabic
                        ? "shadow-[10px_4px_6px_-1px_rgb(0,0,0,0.1)]"
                        : "shadow-[0_4px_6px_-1px_rgb(0,0,0,0.1)]"
                    }`
                  : ""
              }
              
              ${isSelected ? "border-b-0" : "border-b"}
              border-b-grey
              `}
              onClick={() => onPressMenu(item.key)}
            >
              <ViewReverse className={`gap-3 items-center`}>
                <ReactSVG src={isSelected ? item.activeIcon : item.disableIcon} />
                <Text
                  text={getContentKey(item?.key)}
                  size={fontSizes.h14}
                  weight={isSelected ? TextWeight.regular : TextWeight.light}
                  color={`${isSelected ? "text-light-blue" : "text-dark-grey"}`}
                />
                {item?.childs?.length > 0 && (
                  <ReactSVG
                    src={isSelected ? icons.upArrowSelected : icons.downArrow}
                  />
                )}
              </ViewReverse>
              {isSelected && item?.childs?.length > 0 && (
                <ViewItems className={'mt-2 ms-8'}>
                  {item.childs.map((child) => {
                    const isChildSelected = selectedChild == child.key
                    return(
                      <div onClick={() => setSelectedChild(child.key)} className="cursor-pointer">
                        <Text
                          text={ isChildSelected ? `— ${getContentKey(child.key)}` : getContentKey(child.key)}
                          size={fontSizes.h14}
                          weight={isChildSelected ? TextWeight.bold : TextWeight.light}
                          color={isChildSelected ? 'text-light-blue' : 'text-dark-grey'}
                          className={`${isChildSelected ? '' : 'ms-4'} my-1`}
                        />
                      </div>
                  )})}
                </ViewItems>
              )}
            </div>
          );
        })}
      </div>
      <div
        className={`col-span-12 lg:col-span-9 bg-white rounded-md ${
          isArabic
            ? "shadow-[-10px_5px_15px_0px_rgba(0,0,0,0.1)]"
            : "shadow-[10px_5px_15px_0px_rgba(0,0,0,0.1)]"
        } p-4 md:p-8`}
      >
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
