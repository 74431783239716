import React from "react";

const FullScreenLoader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="border-4 border-t-4 border-transparent border-t-primary rounded-full w-12 h-12 animate-spin mx-auto"></div>
    </div>
  );
};

export default FullScreenLoader;
