import React, { useState } from "react";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import Text from "../../../components/Text";
import ViewReverse from "../../../components/ViewReverse";
import { ReactSVG } from "react-svg";
import icons from "../../../constants/icons";
import CustomSwitch from "../../../components/CustomSwitch";
import CustomInput from "../../../components/CustomInput";
import CustomDropdown from "../../../components/CustomDropdown";
import { dropdownOptions } from "../../../config/config";
import CustomCheckbox from "../../../components/CustomCheckbox";
import ViewItems from "../../../components/ViewItems";
import Button from "../../../components/Button";
import { getContentKeyAccToLang } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { rowReverseStyle, rowReverseStylesm } from "../../../utils/styles";

const SwitchFunds = () => {
  const { TextWeight, isArabic } = dynamicfunction();
  const { fundsContent } = useSelector((state) => state.content);

  const [fundsData, setFundsData] = useState({
    portfolio: "",
    fund: "",
    class: "",
    asset: "",
    subFund: "",
    subClass: "",
    risk: false,
    fundDocument: false,
  });
  const [process, setProcess] = useState("inProgress");

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      fundsContent?.web_funds_switch?.data?.attributes,
      key,
      isArabic
    );
  };

  const onChangeData = (key, val) => {
    setFundsData((prev) => ({ ...prev, [key]: val }));
  };

  return (
    <div>
      <Text
        text={process != "recieved" ? getContentKey("heading") : ""}
        size={fontSizes.h24}
        weight={TextWeight.headingBold}
        color="text-dark-blue"
      />
      <Text
        text={
          process === "inProgress"
            ? getContentKey("complete_request")
            : process === "review"
            ? getContentKey("summary_request")
            : ""
        }
        size={fontSizes.h20}
        weight={TextWeight.regular}
        color="text-light-blue"
        className={"mb-3"}
      />

      {process === "inProgress" ? (
        <ViewItems>
          <ViewReverse className={`sm:h-[78vh] gap-4 w-full justify-between flex-col ${rowReverseStylesm(isArabic)}`}
          
          >
            <div className="px-6 py-3 rounded border border-grey w-full sm:w-[45%] h-full">
              <Text
                text={getContentKey("redemption_from")}
                color="text-primary-light"
                size={fontSizes.h14}
                isColon
              />
              <ViewReverse className={"items-center gap-4 mt-4"}>
                <Text
                  text={getContentKey("switch")}
                  color="text-primary-light"
                  size={fontSizes.h14}
                  weight={TextWeight.light}
                />
                <CustomSwitch
                  leftText={getContentKey("partial")}
                  rightText={getContentKey("total")}
                />
              </ViewReverse>
              <CustomDropdown
                label={getContentKey("portfolio")}
                options={dropdownOptions}
                selectedValue={fundsData.portfolio}
                onSelect={(val) => onChangeData("portfolio", val)}
                className={"mt-4"}
              />
              <CustomDropdown
                label={getContentKey("fund")}
                options={dropdownOptions}
                selectedValue={fundsData.fund}
                onSelect={(val) => onChangeData("fund", val)}
                className={"mt-4"}
              />
              <CustomDropdown
                label={getContentKey("class")}
                options={dropdownOptions}
                selectedValue={fundsData.class}
                onSelect={(val) => onChangeData("class", val)}
                className={"mt-4"}
              />
              <CustomInput
                label={getContentKey("redemption_amount")}
                placeholder={getContentKey("quantity_placeholder")}
                isFullborder
                isRounded
                divClasses={"mt-4"}
              />
              <Text
                text={getContentKey("or")}
                color="text-primary-light"
                size={fontSizes.h14}
                className={"mt-3"}
              />
              <CustomInput
                label={getContentKey("units")}
                placeholder={getContentKey("quantity_placeholder")}
                isFullborder
                isRounded
                divClasses={"mt-2"}
              />
            </div>
            <ReactSVG
              src={icons.rightArow}
              className={`w-full sm:w-[10%] mx-auto rotate-90 sm:rotate-0 my-4 sm:my-0 flex justify-center items-center ${
                isArabic && "sm:rotate-180"
              }`}
            />
            <div className="px-6 py-3 rounded border border-grey w-full sm:w-[45%] h-full">
              <Text
                text={getContentKey("subscription_to")}
                color="text-primary-light"
                size={fontSizes.h14}
              />
              <CustomDropdown
                label={getContentKey("asset_class")}
                options={dropdownOptions}
                selectedValue={fundsData.asset}
                onSelect={(val) => onChangeData("asset", val)}
                className={"mt-4"}
              />
              <CustomDropdown
                label={getContentKey("fund")}
                options={dropdownOptions}
                selectedValue={fundsData.subFund}
                onSelect={(val) => onChangeData("subFund", val)}
                className={"mt-4"}
              />
              <CustomDropdown
                label={getContentKey("class")}
                options={dropdownOptions}
                selectedValue={fundsData.subClass}
                onSelect={(val) => onChangeData("subClass", val)}
                className={"mt-4"}
              />
              <CustomCheckbox
                label={getContentKey("understand_agree")}
                secondText={getContentKey("risk")}
                isChecked={fundsData.risk}
                onClick={() => onChangeData("risk", !fundsData.risk)}
                className={"mt-7 mb-2"}
              />
              <CustomCheckbox
                label={getContentKey("read_agree")}
                secondText={getContentKey("fund_document")}
                isChecked={fundsData.fundDocument}
                onClick={() =>
                  onChangeData("fundDocument", !fundsData.fundDocument)
                }
              />
            </div>
          </ViewReverse>

          <ViewReverse className="items-center w-full justify-end gap-4 mt-8">
            <Button
              text={getContentKey("clear_btn")}
              bgColor="bg-white"
              color={"text-dark-grey"}
              className={
                "w-36 text-center border border-dark-grey border-opacity-50"
              }
            />
            <Button
              text={getContentKey("review_btn")}
              bgColor="bg-orange"
              className={"w-36 text-center border border-orange"}
              onClick={() => setProcess("review")}
            />
          </ViewReverse>
        </ViewItems>
      ) : process === "review" ? (
        <ViewItems>
          <ViewReverse className={`w-full gap-2 sm:gap-20 flex-col sm:${rowReverseStyle(isArabic)}`}>
            <ViewItems className={"w-full sm:w-1/2"}>
              <Text
                text={getContentKey("redemption_from")}
                color="text-primary-light"
                size={fontSizes.h14}
                isColon
              />

              <ViewReverse className={"gap-4 w-full"}>
                <div className={"w-2/5 sm:w-1/4 h-[1px] bg-grey my-4"} />
                <div className={"w-full h-[1px] bg-grey my-4"} />
              </ViewReverse>

              <ViewReverse className={"gap-4 w-full"}>
                <Text
                  text={getContentKey("fund_name")}
                  size={fontSizes.h14}
                  color={"text-primary-light"}
                  className={"w-[28%] sm:w-1/5"}
                />
                <Text
                  text={"Alkhobar"}
                  size={fontSizes.h14}
                  weight={TextWeight.light}
                />
              </ViewReverse>

              <ViewReverse className={"gap-4 w-full"}>
                <div className={"w-2/5 sm:w-1/4 h-[1px] bg-grey my-4"} />
                <div className={"w-full h-[1px] bg-grey my-4"} />
              </ViewReverse>

              <ViewReverse className={"gap-4 w-full"}>
                <Text
                  text={getContentKey("date")}
                  size={fontSizes.h14}
                  color={"text-primary-light"}
                  className={"w-[28%] sm:w-1/5"}
                />
                <Text
                  text={"29 February 2021"}
                  size={fontSizes.h14}
                  weight={TextWeight.light}
                />
              </ViewReverse>

              <ViewReverse className={"gap-4 w-full my-3"}>
                <Text
                  text={getContentKey("partial_total")}
                  size={fontSizes.h14}
                  color={"text-primary-light"}
                  className={"w-[28%] sm:w-1/5"}
                />
                <Text
                  text={"Partial"}
                  size={fontSizes.h14}
                  weight={TextWeight.light}
                />
              </ViewReverse>

              <ViewReverse className={"gap-4 w-full"}>
                <Text
                  text={getContentKey("amount")}
                  size={fontSizes.h14}
                  color={"text-primary-light"}
                  className={"w-[28%] sm:w-1/5"}
                />
                <Text
                  text={"$33,000 USD"}
                  size={fontSizes.h14}
                  weight={TextWeight.bold}
                />
              </ViewReverse>

              <ViewReverse className={"gap-4 w-full"}>
                <div className={"w-2/5 sm:w-1/4 h-[1px] bg-grey my-4"} />
                <div className={"w-full h-[1px] bg-grey my-4"} />
              </ViewReverse>
            </ViewItems>

            <ViewItems className={"w-full sm:w-1/2"}>
              <Text
                text={getContentKey("subscription_to")}
                color="text-primary-light"
                size={fontSizes.h14}
                isColon
              />

              <ViewReverse className={"gap-4 w-full"}>
                <div className={"w-2/5 sm:w-1/4 h-[1px] bg-grey my-4"} />
                <div className={"w-full h-[1px] bg-grey my-4"} />
              </ViewReverse>

              <ViewReverse className={"gap-4 w-full"}>
                <Text
                  text={getContentKey("fund_name")}
                  size={fontSizes.h14}
                  color={"text-primary-light"}
                  className={"w-[28%] sm:w-1/5"}
                />
                <Text
                  text={"Alkhobar"}
                  size={fontSizes.h14}
                  weight={TextWeight.light}
                />
              </ViewReverse>

              <ViewReverse className={"gap-4 w-full"}>
                <div className={"w-2/5 sm:w-1/4 h-[1px] bg-grey my-4"} />
                <div className={"w-full h-[1px] bg-grey my-4"} />
              </ViewReverse>

              <ViewReverse className={"gap-4 w-full"}>
                <Text
                  text={getContentKey("class")}
                  size={fontSizes.h14}
                  color={"text-primary-light"}
                  className={"w-[28%] sm:w-1/5"}
                />
                <Text
                  text={"Reserves"}
                  size={fontSizes.h14}
                  weight={TextWeight.light}
                />
              </ViewReverse>

              <ViewReverse className={"gap-4 w-full mt-3"}>
                <Text
                  text={getContentKey("currency")}
                  size={fontSizes.h14}
                  color={"text-primary-light"}
                  className={"w-[28%] sm:w-1/5"}
                />
                <Text
                  text={"USD"}
                  size={fontSizes.h14}
                  weight={TextWeight.bold}
                />
              </ViewReverse>

              <ViewReverse className={"gap-4 w-full"}>
                <div className={"w-2/5 sm:w-1/4 h-[1px] bg-grey my-4"} />
                <div className={"w-full h-[1px] bg-grey my-4"} />
              </ViewReverse>
            </ViewItems>
          </ViewReverse>

          <ViewReverse className="items-center w-full justify-end gap-2 mt-8">
            <Button
              text={getContentKey("edit_btn")}
              bgColor="bg-white"
              color={"text-dark-grey"}
              className={
                "w-36 text-center border border-dark-grey border-opacity-50"
              }
              onClick={() => setProcess("inProgress")}
            />
            <Button
              text={getContentKey("submit_btn")}
              bgColor="bg-orange"
              className={"w-36 text-center border border-orange"}
              onClick={() => setProcess("recieved")}
            />
          </ViewReverse>
        </ViewItems>
      ) : process === "recieved" ? (
        <div className="w-full flex flex-col justify-center items-center h-[50vh]">
          <div>
            <Text
              text={getContentKey("thank_you")}
              size={fontSizes.h24}
              weight={TextWeight.headingBold}
              color="text-dark-blue"
            />
            <Text
              text={getContentKey("request_recieved")}
              size={fontSizes.h20}
              weight={TextWeight.regular}
              color="text-light-blue"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SwitchFunds;
