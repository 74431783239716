import React from "react";
import Text from "./Text";
import { fontSizes } from "../constants/constants";
import dynamicfunction from "../utils/dynamicfunction";

const ProgressBar = ({ isred = false, text = "", isTextRight = false, percentage = 0 }) => {
    const { TextWeight } = dynamicfunction();

  return (
    <div
      className={`${
        isred ? "bg-red-200" : "bg-green-200"
      } px-3 py-[3px] relative`}
    >
      <Text
        text={text}
        size={fontSizes.h14}
        weight={TextWeight.light}
        className={`opacity-50 italic ${
          isTextRight ? "text-right" : "text-left"
        }`}
      />
      <div
      style={{
        width: `${percentage}%`
      }}
        className={`absolute top-0 z-10 h-full ${
          isTextRight ? "left-0" : "right-0"
        } ${isred ? "bg-red-500" : "bg-green-500"}
        `}
      />
    </div>
  );
};

export default ProgressBar;
