import React from "react";
import images from "../../constants/images";
import { fontSizes } from "../../constants/constants";
import Language from "../../components/Language";
import CustomInput from "../../components/CustomInput";
import { ReCAPTCHA } from "react-google-recaptcha";
import Text from "../../components/Text";
import Button from "../../components/Button";
import ViewReverse from "../../components/ViewReverse";
import ViewItems from "../../components/ViewItems";
import icons from "../../constants/icons";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import loginFunctional from "./index.function";
import CustomCheckbox from "../../components/CustomCheckbox";

const Login = () => {
  const {
    TextWeight,
    isArabic,
    authLoading,
    loginError,
    otpError,
    loginDetails,
    isCode,
    isCountStart,
    otp,
    isResendCode,
    timerKey,
    setIsResendCode,
    setOtp,
    hnadleInputChange,
    onChange,
    handleLogin,
    handleVerifyOtp,
    handleResendCode,
    onBack,
    getContentKey,
  } = loginFunctional();

  const timerCount = ({ remainingTime }) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
      <Text
        text={`${minutes > 9 ? minutes : `0${minutes}`}:${
          seconds > 9 ? seconds : `0${seconds}`
        }`}
        size={fontSizes.h23}
        color={"text-light-blue"}
        weight={TextWeight.light}
      />
    );
  };

  return (
    <div className="grid grid-cols-2 h-screen">
      <div
        className={`hidden md:flex col-span-1 bg-no-repeat bg-cover bg-bottom flex-col justify-end items-${
          isArabic ? "end" : "start"
        } py-14 px-20 ${isArabic ? "order-last" : ""}`}
        style={{
          backgroundImage: `url(${images.loginBg})`,
        }}
      >
        <div>
          <Text
            text={getContentKey("heading")}
            weight={TextWeight.headingBold}
            color={"text-white"}
            size={ isArabic ? fontSizes.h70 : fontSizes.h60}
          />
        </div>
        <div>
          <Text
            text={getContentKey("sub_heading")}
            weight={TextWeight.headingRegular}
            color={"text-white"}
            size={fontSizes.h36}
            className={"-mt-4"}
          />
        </div>
      </div>

      <div className={`col-span-2 md:col-span-1 h-full flex flex-col`}>
        <ViewReverse className={`justify-end gap-6 `}>
          <Language />
          <img src={images.logo} width={80} />
        </ViewReverse>
        <div className="flex flex-1 justify-center items-center">
          <ViewItems className={`w-3/4 md:w-1/2 lg:w-1/2`}>
            <Text
              text={getContentKey("login")}
              weight={TextWeight.headingLight}
              size={fontSizes.h38}
              color={"text-black"}
              className={"mb-4"}
            />

            <CustomInput
              placeholder={getContentKey("username_placeholder")}
              isFullborder={false}
              isDisabled={isCode}
              value={loginDetails.userName}
              onChange={(e) => hnadleInputChange("userName", e.target.value)}
              isError={
                loginError?.includes("Username is required") ||
                loginError?.includes("Invalid username or password")
              }
            />

            {loginError?.includes("Username is required") && (
              <Text
                text={getContentKey("username_required")}
                weight={TextWeight.light}
                className={"mt-2"}
                color={"text-red-600"}
              />
            )}
            {loginError?.includes("Invalid username or password") && (
              <Text
                text={getContentKey("invalid")}
                weight={TextWeight.light}
                className={"mt-2"}
                color={"text-red-600"}
              />
            )}

            <CustomInput
              placeholder={getContentKey("password_placeholder")}
              type="password"
              className={"mt-6"}
              isDisabled={isCode}
              isFullborder={false}
              value={loginDetails.password}
              onChange={(e) => hnadleInputChange("password", e.target.value)}
              isError={loginError?.includes("Password is required")}
            />

            {loginError?.includes("Password is required") && (
              <Text
                text={getContentKey("password_required")}
                weight={TextWeight.light}
                className={"mt-2"}
                color={"text-red-600"}
              />
            )}

            {isCode ? (
              <div className="pt-4 pb-6 px-6 shadow-[0_0px_15px_0px_rgba(0,0,0,0.1)] bg-white rounded w-full mt-4">
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={onBack}
                >
                  <img src={icons.back} />
                  <Text
                    text={getContentKey("back")}
                    color={"text-gray-400"}
                    weight={TextWeight.bold}
                  />
                </div>
                <div className="w-fit self-center mx-auto mb-6">
                  <CountdownCircleTimer
                    key={timerKey}
                    isPlaying={isCountStart}
                    duration={10}
                    colors={["#516AC7"]}
                    strokeWidth={5}
                    size={90}
                    trailColor="#D9D8D5"
                    strokeLinecap="square"
                    rotation="counterclockwise"
                    onComplete={() => {
                      setIsResendCode(true);
                    }}
                  >
                    {timerCount}
                  </CountdownCircleTimer>
                </div>
                <CustomInput
                  placeholder={getContentKey("otp_placeholder")}
                  isFullborder={false}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  isError={otpError}
                />

                {otpError && (
                  <Text
                    text={
                      otpError === "Invalid otp"
                        ? getContentKey("invalid_otp")
                        : otpError === "The OTP Code is required"
                        ? getContentKey("otp_required")
                        : otpError
                    }
                    weight={TextWeight.light}
                    className={"mt-2"}
                    color={"text-red-600"}
                  />
                )}

                <div className={"flex justify-between mt-6 gap-2"}>
                  <Button
                    text={getContentKey("resend_otp")}
                    bgColor="bg-transparent"
                    color="text-black"
                    weight={TextWeight.regular}
                    className={"border border-grey px-7"}
                    isDisabled={!isResendCode}
                    onClick={handleResendCode}
                  />
                  <Button
                    text={getContentKey("confirm_otp")}
                    bgColor="bg-orange"
                    className={"px-7"}
                    weight={TextWeight.regular}
                    isDisabled={authLoading}
                    onClick={handleVerifyOtp}
                  />
                </div>
              </div>
            ) : (
              <>
                <ViewReverse className={`justify-between mt-3 w-full`}>
                  <ViewReverse className={`items-center gap-2`}>
                    <CustomCheckbox type="checkbox" className={"w-3"} />
                    <Text
                      text={getContentKey("remember")}
                      color={"text-primary-dark"}
                    />
                  </ViewReverse>

                  <Text
                    text={getContentKey("forgot_password")}
                    color={"text-primary-dark"}
                  />
                </ViewReverse>

                <div>
                  <ReCAPTCHA
                    sitekey="Your client site key"
                    onChange={onChange}
                  />
                </div>

                <Button
                  text={getContentKey("login_btn")}
                  className={"mt-6 my-2 w-28"}
                  onClick={handleLogin}
                  isDisabled={authLoading}
                />

                <ViewReverse>
                  <Text
                    text={getContentKey("ask_new_account")}
                    className={"mx-1"}
                  ></Text>
                  <Text
                    text={getContentKey("new_account")}
                    color={"text-blue-500"}
                    weight={TextWeight.bold}
                  />
                </ViewReverse>
              </>
            )}
          </ViewItems>
        </div>
      </div>
    </div>
  );
};

export default Login;
