import React, { useState } from "react";
import images from "../constants/images";
import HeaderDropdown from "./HeaderDropdown";
import icons from "../constants/icons";
import Language from "./Language";
import { useNavigate } from "react-router";
import dynamicFunction from "../utils/dynamicfunction";

const menuList = [
  {
    name: "item 1",
    route: "none",
  },
  {
    name: "item 2",
    route: "none",
  },
  {
    name: "item 3",
    route: "none",
  },
];

const Header = () => {
  const navigate = useNavigate();
  const { TextWeight } = dynamicFunction();
  const [isMobileMenu, setIsMobileMenu] = useState(false);

  const toogleMobileMenu = () => setIsMobileMenu(!isMobileMenu);

  return (
    <div className="flex items-center justify-between">
      <div>
        <img src={images.textLogo} alt="logo" width={200} />
      </div>
      <div className="gap-4 items-center hidden md:flex">
        <HeaderDropdown label={"menu 1"} items={menuList} />
        <HeaderDropdown label={"menu 2"} items={menuList} />
        <HeaderDropdown label={"menu 3"} items={menuList} />
        <HeaderDropdown label={"menu 4"} items={menuList} />
        <HeaderDropdown label={"menu 5"} items={menuList} />
        <HeaderDropdown label={"menu 6"} items={menuList} />

        <img src={icons.search} />

        <Language />

        <button
          className={`text-primary-dark text-[12px] font-semibold uppercase ${TextWeight.bold}`}
          onClick={() => navigate("/login")}
        >
          Login
        </button>
        <button
          className={`text-white bg-orange uppercase rounded px-6 py-2 text-[12px] ${TextWeight.bold}`}
        >
          Register
        </button>
      </div>

      <div className="cursor-pointer md:hidden" onClick={toogleMobileMenu}>
        <img src={icons.menuList} />
      </div>

      {isMobileMenu && (
        <div className="absolute top-0 right-0 w-2/3 h-screen bg-white p-4 transition-all duration-200">
          <div>
            <img
              width="30"
              height="30"
              src="https://img.icons8.com/ios-glyphs/30/multiply.png"
              alt="multiply"
              onClick={toogleMobileMenu}
            />
          </div>
          <div>
            <img
              className="mx-auto"
              src={images.textLogo}
              alt="logo"
              width={200}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
