import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  marketSummary: null,
  portfolioSummary: null,
};

const tradingSlice = createSlice({
  name: "trading",
  initialState,
  reducers: {
    setMarketSummary: (state, action) => {
      state.marketSummary = action.payload;
    },
    setPortfolioSummary: (state, action) => {
      state.portfolioSummary = action.payload;
    },
  },
});

export const { setMarketSummary, setPortfolioSummary } = tradingSlice.actions;
export default tradingSlice.reducer;
