import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const [selected, setSelected] = useState(searchParams.get("page"));

  const onPressMenu = (val) => {
    setSearchParams((params) => {
      params.set("page", val);
      return params;
    });
  };

  useEffect(() => {
    if (searchParams.get("page")) {
      setSelected(searchParams.get("page"));
    } else {
      setSearchParams((params) => {
        params.set("page", "account_statement");
        return params;
      });
    }
  }, [searchParams]);

  return {
    selected,
    onPressMenu,
  };
};
