import React from "react";
import ViewReverse from "../../../components/ViewReverse";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import { useSelector } from "react-redux";
import { ConfigProvider, Divider, Table } from "antd";
import Loader from "../../../components/Loader";
import { brokerageHoldingsColumns, cashBalanceColumns } from "../../../config/config";
import { getContentKeyAccToLang } from "../../../utils/utils";

const Brokerage = () => {
  const { TextWeight, isArabic } = dynamicfunction();
  const { brokerage } = useSelector((state) => state.investments);
  const { investmentContent } = useSelector((state) => state.content);

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      investmentContent?.web_investment_brokerage?.data?.attributes,
      key,
      isArabic,
    );
  };

  return (
    <div>
      {brokerage ? (
        <>
          <ViewReverse className={"items-end justify-between mb-4"}>
            <Text
              text={getContentKey("heading")}
              weight={TextWeight.headingBold}
              size={fontSizes.h24}
              color={"text-dark-blue"}
            />
            <ViewReverse className={"items-center gap-2"}>
              <Text text={"A"} color={"text-dark-grey"} />
              <Text
                text={"A"}
                size={fontSizes.h16}
                color={"text-dark-grey"}
                className={"mb-[1px]"}
              />
              <Text
                text={"A"}
                size={fontSizes.h20}
                color={"text-dark-grey"}
                className={"mb-[2px]"}
              />
            </ViewReverse>
          </ViewReverse>

          <div className="border border-grey py-4 md:py-10 px-4 md:px-6 rounded-md">
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: "#E0E0E0",
                    headerSplitColor: "transparent",
                    borderRadius: 0,
                    padding: 20,
                  },
                },
              }}
            >
              <Table
                columns={
                  isArabic
                    ? brokerageHoldingsColumns(TextWeight, getContentKey)?.reverse()
                    : brokerageHoldingsColumns(TextWeight, getContentKey)
                }
                dataSource={brokerage?.currentInvestments}
                pagination={false}
                className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
                scroll={{
                  x: "max-content",
                }}
              />
            </ConfigProvider>
          </div>

          <div className="border border-grey pt-4 pb-4 md:pb-10 px-4 md:px-6 rounded-md mt-6">
            <Text
              text={getContentKey("cash_balance")}
              size={fontSizes.h14}
              color={"text-primary-light"}
            />

            <Divider className="mt-2" />

            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: "#E0E0E0",
                    headerSplitColor: "transparent",
                    borderRadius: 0,
                    padding: 20,
                  },
                },
              }}
            >
              <Table
                columns={
                  isArabic
                    ? cashBalanceColumns(TextWeight, getContentKey)?.reverse()
                    : cashBalanceColumns(TextWeight, getContentKey)
                }
                dataSource={brokerage?.cashBalance}
                pagination={false}
                className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
                scroll={{
                  x: "max-content",
                }}
              />
            </ConfigProvider>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Brokerage;
