import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getBeneficiaryData, getTransferInfo } from "../../store/actions/transferAction";

export default () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState(searchParams.get("page"));

  const onPressMenu = (val) => {
    setSearchParams((params) => {
      params.set("page", val);
      return params;
    });
  };

  useEffect(() => {
    if (searchParams.get("page")) {
      setSelected(searchParams.get("page"));
    } else {
      setSearchParams((params) => {
        params.set("page", "transfers");
        return params;
      });
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(getTransferInfo());
    dispatch(getBeneficiaryData());
  }, [])

  return {
    selected,
    onPressMenu
  };
};
