import { useSelector } from "react-redux";
import dynamicfunction from "../../utils/dynamicfunction";
import { getContentKeyAccToLang } from "../../utils/utils";

export default () => {
  const { TextWeight, isArabic } = dynamicfunction();
  const { authContent } = useSelector((state) => state.content);
  
  const getContentKey = (key) => {
    return getContentKeyAccToLang(authContent?.web_hero?.data?.attributes, key, isArabic);
  }

  return {
    TextWeight,
    getContentKey,
  };
};
