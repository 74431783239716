import React, { useState } from "react";
import Text from "../../../components/Text";
import { fontSizes } from "../../../constants/constants";
import dynamicfunction from "../../../utils/dynamicfunction";
import ViewItems from "../../../components/ViewItems";
import ViewReverse from "../../../components/ViewReverse";
import CustomDropdown from "../../../components/CustomDropdown";
import { emailOptions, monthsOptions } from "../../../config/config";
import { ReactSVG } from "react-svg";
import icons from "../../../constants/icons";
import { Modal } from "antd";
import Button from "../../../components/Button";
import { getContentKeyAccToLang } from "../../../utils/utils";
import { useSelector } from "react-redux";

const AccountStatement = () => {
  const { reportsContent } = useSelector(state => state.content);
  const { TextWeight, isArabic } = dynamicfunction();

  const [selectedMonth, setSelectedMonth] = useState(0);
  const [emailModal, setEmailModal] = useState(false);
  const [recipient, setRecipient] = useState(0);

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      reportsContent?.web_account_statement?.data?.attributes,
      key,
      isArabic
    );
  };

  const onSelectMonth = (val) => setSelectedMonth(val);
  const toogleEmailModal = () => setEmailModal(!emailModal);

  return (
    <div>
      <Text
        text={getContentKey("reports")}
        size={fontSizes.h24}
        weight={TextWeight.headingBold}
        color="text-dark-blue"
      />
      <ViewItems className={"mb-28"}>
        <ViewReverse
          className={
            "border border-grey px-5 py-3 rounded w-full md:w-2/3 mt-5 gap-4 justify-between pb-24"
          }
        >
          <ViewItems className={"w-2/3 sm:w-2/3 justify-between"}>
            <div className="w-full">
              <Text
                text={getContentKey("account_statement")}
                size={fontSizes.h14}
                color={"text-primary-light"}
                className={"mb-3"}
              />
              <CustomDropdown
                label={getContentKey("select_statement_month")}
                onSelect={onSelectMonth}
                options={monthsOptions(getContentKey)}
                selectedValue={selectedMonth}
                isBorder
              />
            </div>

            {selectedMonth !== 0 && (
              <ViewReverse className={"w-full justify-end"}>
                <Text
                  text={getContentKey("view")}
                  size={fontSizes.h14}
                  color="text-light-blue"
                />
                <Text
                  text="|"
                  size={fontSizes.h14}
                  color="text-light-blue"
                  className={"px-1"}
                />
                <Text
                  text={getContentKey("download")}
                  size={fontSizes.h14}
                  color="text-light-blue"
                />
                <Text
                  text="|"
                  size={fontSizes.h14}
                  color="text-light-blue"
                  className={"px-1"}
                />
                <Text
                  text={getContentKey("email")}
                  size={fontSizes.h14}
                  color="text-light-blue"
                  onClick={toogleEmailModal}
                  className={"cursor-pointer"}
                />
              </ViewReverse>
            )}
          </ViewItems>

          <div className="w-1/3 sm:w-1/4 mt-4 sm:me-4 flex justify-center items-center bg-[#F0F0F0] p-10 rounded">
            <ReactSVG src={icons.pdf} />
          </div>
        </ViewReverse>
      </ViewItems>

      <Modal
        open={emailModal}
        closeIcon={false}
        footer={null}
        centered
        className="top-5"
      >
        <ViewReverse className={"justify-between items-center p-4"}>
          <Text
            text={getContentKey("send_email")}
            size={fontSizes.h20}
            color="text-dark-blue"
          />
          <ReactSVG src={icons.cross} className="opacity-50 cursor-pointer" onClick={toogleEmailModal} />
        </ViewReverse>

        <CustomDropdown
          label={getContentKey("to")}
          onSelect={setRecipient}
          options={emailOptions}
          selectedValue={recipient}
          isBorder
          className={"mt-4"}
        />

        <ViewReverse className="items-center w-full justify-end gap-2 mt-8">
          <Button
            text={getContentKey("send_btn")}
            bgColor="bg-white"
            color={"text-dark-grey"}
            className={
              "w-36 text-center border border-dark-grey border-opacity-50"
            }
          />
          <Button
            text={getContentKey("cancel_btn")}
            bgColor="bg-orange"
            className={"w-36 text-center border border-orange"}
            onClick={toogleEmailModal}
          />
        </ViewReverse>
      </Modal>
    </div>
  );
};

export default AccountStatement;
