import apiServices from "../../ApiServices/request-handler";
import { showToast } from "../../utils/utils";
import { setBeneficiaryData, setTransferInfo } from "../slices/transferSlice";


const getTransferInfo = () => {
    return async (dispatch) => {
        try {
            const response = await apiServices.getTransferInfo();
            dispatch(setTransferInfo(response?.result));
        } catch (error) {
        }
    }
}

const createTransfer = async (data) => {
    try {
        console.log('data', data)
        const response = await apiServices.createTransfer(data);
        console.log('response', response)
        showToast('success', response?.message);
    } catch (error) {
        showToast('error', error?.data?.message);
        console.log('error create transfer', error)
    }
}

const getTransactions = (data, transferInfo) => {
    return async (dispatch) => {
        try {
            const response = await apiServices.getTransactions(data);
            dispatch(setTransferInfo({...transferInfo, transactionList: response?.result?.transactionList}));
        } catch (error) {
        }
    }
}

const getBeneficiaryData = () => {
    return async (dispatch) => {
        try {
            const response = await apiServices.getBeneficiaryData();
            dispatch(setBeneficiaryData(response?.result));
        } catch (error) {
        }
    }
}

export {
    getTransferInfo,
    createTransfer,
    getTransactions,
    getBeneficiaryData
};