import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../store/actions/contentAction";
import { languages } from "../config/config";
import dynamicfunction from "../utils/dynamicfunction";

const Language = () => {
  const dispatch = useDispatch();
  const { isArabic } = dynamicfunction();

  const handleLanguagePress = (lang) => {
    dispatch(changeLanguage(lang));
  };

  return (
    <div className="flex items-center gap-2">
      <span
        className={`text-[12px] text-primary-dark cursor-pointer avenir-heavy ${isArabic ? 'opacity-40' : ''}`}
        onClick={() => handleLanguagePress(languages.eng)}
      >
        ENG
      </span>
      <div className="w-[2px] bg-primary-dark h-6" />
      <span
        className={`text-[12px] text-primary-dark text-left mb-[5px] cursor-pointer almarai-bold ${!isArabic ? 'opacity-40' : ''}`}
        onClick={() => handleLanguagePress(languages.arb)}
      >
        عربي
      </span>
    </div>
  );
};

export default Language;
