import React, { useState } from "react";
import icons from "../constants/icons";
import dynamicFunction from "../utils/dynamicfunction";

const HeaderDropdown = ({ label, items }) => {

  const { TextWeight, TextAlign } = dynamicFunction();

  const [isShown, setIsShown] = useState(false);
  const showDropdown = () => setIsShown(true);
  const hideDropdown = () => setIsShown(false);

  return (
    <div
      className="relative"
      onMouseEnter={showDropdown}
      onMouseLeave={hideDropdown}
    >
      <div className="flex gap-2 cursor-pointer">
        <span
          className={`uppercase text-[12px] text-primary-dark ${TextWeight.bold} ${TextAlign}`}
        >
          {label}
        </span>
        <img src={icons.downArrow} />
      </div>

      {isShown && (
        <div className="bg-white absolute top-[18px] w-24">
          {items?.map((item, index) => (
            <div
              key={index}
              className={`px-4 py-2 hover:bg-primary text-primary-dark hover:text-white transition-all duration-300 cursor-pointer ${TextWeight.regular} ${TextAlign}`}
            >
              <span>{item?.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HeaderDropdown;
