import React, { useEffect, useRef } from "react";
import dynamicFunction from "../utils/dynamicfunction";
import Text from "./Text";
import { fontSizes } from "../constants/constants";

const CustomInput = ({
  type = "text",
  value,
  onChange,
  isFullborder = true,
  isRounded = false,
  placeholder,
  isError = false,
  className,
  label,
  divClasses,
  isRequired = false,
  errorMessage,
  isDisabled = false,
  width = "w-full"
}) => {
  const { TextWeight, TextAlign, isArabic } = dynamicFunction();

  return (
    <div className={`${width} ${divClasses}`}>
      <Text
      text={label && label}
      size={fontSizes.h14}
      color={ isError ? 'text-red-500' : 'text-primary-light'}
      className={'mb-1'}
      isRequired={isRequired}
      />
      <input
        type={type}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        placeholder={placeholder}
        className={`px-3 py-2 ${isFullborder ? "border" : "border-b-2"} ${
          isError ? "border-red-600" : "border-gray-400"
        } ${isRounded ? "rounded" : ""} w-full outline-none border-opacity-50 ${className} ${
          TextWeight.light
        } ${TextAlign}`}
      />
      {
        errorMessage && <Text text={errorMessage} color="text-red-500" className={'mt-2'} weight={TextWeight.light} />
      }
    </div>
  );
};

export default CustomInput;
