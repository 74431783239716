import React from "react";
import Text from "./Text";
import { fontSizes } from "../constants/constants";
import dynamicfunction from "../utils/dynamicfunction";

const CustomTable = ({ tableData }) => {
  const { TextWeight, isArabic } = dynamicfunction();
  return (
    <div className={`w-full rounded border border-grey`}>
      {tableData?.map((item, index) => {
        const isLast = tableData.length - 1 == index;
        return (
          <div
          dir={isArabic ? 'rtl' : 'ltr'}
          key={index}
            className={`${
              item?.id == 0 ? "bg-grey" : ""
            } grid grid-cols-3 py-3 px-6 ${
              !isLast && "border-b"
            } border-b-grey`}
          >
            <div className="col-span-1">
              <Text
                text={item?.col1}
                size={fontSizes.h14}
                weight={
                  index == 0 || isLast ? TextWeight.bold : TextWeight.light
                }
                color={isLast ? 'text-light-blue' : 'text-dark-grey'}
              />
            </div>
            <div className="col-span-1 text-right">
              <Text
                text={item?.col2}
                className={"text-right"}
                size={fontSizes.h14}
                weight={
                  index == 0 || isLast ? TextWeight.bold : TextWeight.light
                }
                color={isLast ? 'text-light-blue' : 'text-dark-grey'}
              />
            </div>
            <div className="col-span-1">
              <Text
                text={item?.col3}
                className={"text-right"}
                size={fontSizes.h14}
                weight={
                  index == 0 || isLast ? TextWeight.bold : TextWeight.light
                }
                color={isLast ? 'text-light-blue' : 'text-dark-grey'}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomTable;
