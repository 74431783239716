import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Router from "./router/Router";
import "./assets/fonts/Butler/Butler_Black.otf";
import "./assets/fonts/Butler/Butler_Bold.otf";
import "./assets/fonts/Butler/Butler_ExtraBold.otf";
import "./assets/fonts/Butler/Butler_Light.otf";
import "./assets/fonts/Butler/Butler_Medium.otf";
import "./assets/fonts/Butler/Butler_Regular.otf";
import "./assets/fonts/Avenir/AvenirLTStd-Black.otf";
import "./assets/fonts/Avenir/AvenirLTStd-Book.otf";
import "./assets/fonts/Avenir/AvenirLTStd-Heavy.otf";
import "./assets/fonts/Avenir/AvenirLTStd-Light.otf";
import "./assets/fonts/Avenir/AvenirLTStd-Medium.otf";
import "./assets/fonts/Avenir/AvenirLTStd-Roman.otf";
import "./assets/fonts/Almarai/Almarai-Bold.ttf";
import "./assets/fonts/Almarai/Almarai-ExtraBold.ttf";
import "./assets/fonts/Almarai/Almarai-Light.ttf";
import "./assets/fonts/Almarai/Almarai-Regular.ttf";
import { Provider } from "react-redux";
import store, { persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
