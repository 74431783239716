import React from 'react'
import Sidebar from '../../components/Sidebar'
import { transferMenu } from '../../config/config'
import Beneficiaries from './components/beneficiaries'
import Transfer from './components/transfer'
import transferFunctional from "./index.function"

const TransferMain = () => {
    const { selected, onPressMenu } = transferFunctional();

  return (
    <Sidebar
      menuList={transferMenu}
      selected={selected}
      onPressMenu={onPressMenu}
    >
      {selected == "transfer" ? (
       <Transfer/>
      ) : selected == "beneficiaries" ? (
        <Beneficiaries/>
      ) : null}
    </Sidebar>
  )
}

export default TransferMain