import React from "react";
import fundsFunctional from "./index.function";
import Sidebar from "../../components/Sidebar";
import { fundsMenu } from "../../config/config";
import Subscribe from "./components/Subscribe";
import Redeem from "./components/Redeem";
import SwitchFunds from "./components/SwitchFunds";
import Transactions from "./components/Transactions";

const Funds = () => {
  const { selected, onPressMenu } = fundsFunctional();
  return (
    <Sidebar menuList={fundsMenu} onPressMenu={onPressMenu} selected={selected}>
      {selected === "subscribe" ? (
        <Subscribe />
      ) : selected === "redeem" ? (
        <Redeem />
      ) : selected === "switch" ? (
        <SwitchFunds />
      ) : selected === "transactions" ? (
        <Transactions />
      ) : null}
    </Sidebar>
  );
};

export default Funds;
