import React from "react";
import ViewReverse from "./ViewReverse";
import Text from "./Text";
import { fontSizes } from "../constants/constants";
import dynamicfunction from "../utils/dynamicfunction";
import { ConfigProvider, Switch } from "antd";

const CustomSwitch = ({ leftText, rightText, checked, onChange }) => {
  const { TextWeight } = dynamicfunction();
  return (
    <ViewReverse className={"items-center gap-3"}>
      <Text text={leftText} size={fontSizes.h14} weight={TextWeight.light} />
      <ConfigProvider
      theme={{
        components: {
            Switch: {
                handleSize: 14,
                handleBg: '#bababa',
            }
        }
      }}
      >
        <Switch
          checked={checked}
          onChange={onChange}
          style={{
            accentColor: "red",
            backgroundColor: "transparent",
            border: "2px solid #bababa",
          }}
        />
      </ConfigProvider>
      <Text text={rightText} size={fontSizes.h14} weight={TextWeight.light} />
    </ViewReverse>
  );
};

export default CustomSwitch;
