import { useSelector } from "react-redux";
import { languages } from "../config/config";

export default () => {
  const { language } = useSelector((state) => state.content);

  const isArabic = language === languages.arb;

  const TextWeight = {
    bold: isArabic ? "almarai-bold" : "avenir-heavy",
    regular: isArabic ? "almarai-regular" : "avenir-medium",
    light: isArabic ? "almarai-light" : "avenir-light",
    extraBold: isArabic ? "almarai-extra-bold" : "avenir-black",

    headingBold: isArabic ? "almarai-bold" : "butler-bold",
    headingRegular: isArabic ? "almarai-regular" : "butler-regular",
    headingLight: isArabic ? "almarai-light" : "butler-light",
    headingExtraBold: isArabic ? "almarai-extra-bold" : "almarai-light",
  };

  const TextAlign = isArabic ? "text-right" : "text-left";

  return { isArabic, TextWeight, TextAlign };
};
