import axios from "axios";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

export const showToast = (icon, text) => {
  Toast.fire({
    icon: icon,
    title: text,
  });
};

export const getContentKeyAccToLang = (data, key, isArabic, isColon = false) => {
  if (data) {
    if (isColon) {
      if (isArabic) return ":" + data[key + "_arabic"];
      return data[key] + ":";
    }
    if (isArabic) return data[key + "_arabic"];
    return data[key];
  }
  return "";
};
