import { createSlice } from '@reduxjs/toolkit';
import { languages } from '../../config/config';

const initialState = {
  language: languages.eng,
  authContent: null,
  dashboardContent: null,
  settingContent: null,
  investmentContent: null,
  fundsContent: null,
  reportsContent: null,
};

const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload.language;
        },
        setContent: (state, action) => {
            state.authContent = action.payload.authContent;
            state.dashboardContent = action.payload.dashboardContent;
            state.settingContent = action.payload.settingContent;
            state.investmentContent = action.payload.investmentContent;
            state.fundsContent = action.payload.fundsContent;
            state.reportsContent = action.payload.reportsContent;
        },
    },
})

export const { setLanguage, setContent } = contentSlice.actions;
export default contentSlice.reducer;