import React from 'react'
import dynamicfunction from '../utils/dynamicfunction';

const ViewItems = ({ children, className }) => {
    const { isArabic } = dynamicfunction();

  return (
    <div className={`flex flex-col ${isArabic ? "items-end" : "items-start"} ${className}`}>{children}</div>
  )
}

export default ViewItems