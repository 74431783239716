import React from 'react'
import ViewReverse from './ViewReverse'
import Text from './Text'
import { fontSizes } from '../constants/constants'
import dynamicfunction from '../utils/dynamicfunction'

const CustomCheckbox = ({ isChecked, label, onClick, className, secondText }) => {
    const { TextWeight } = dynamicfunction();
  return (
    <ViewReverse className={`items-start flex-wrap gap-2 ${className}`}>
        <input type='checkbox' checked={isChecked} onChange={() => {}} onClick={onClick} className='cursor-pointer mt-[3px]' />
        <Text text={label} size={fontSizes.h14} color={'text-dark-grey'} weight={TextWeight.light} onClick={onClick} className={'cursor-pointer'} />
        {
          secondText &&
          <Text text={secondText} size={fontSizes.h14} color={'text-light-blue'} onClick={onClick} weight={TextWeight.bold} className={'-mx-1 cursor-pointer'} />
        }
    </ViewReverse>
  )
}

export default CustomCheckbox