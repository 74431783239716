import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    settingLoading: false,
};

const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        setSettingLoading: (state, action) => {
            state.settingLoading = action.payload;
        },
    },
})

export const { setSettingLoading } = settingSlice.actions;
export default settingSlice.reducer;