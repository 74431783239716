import apiServices from "../../ApiServices/request-handler";
import { showToast } from "../../utils/utils";
import { setMarketSummary, setPortfolioSummary } from "../slices/tradingSlice";


const getMarketSummary = () => {
    return async (dispatch) => {
        try {
            const response = await apiServices.getMarketSummary();
            dispatch(setMarketSummary(response?.result));
        } catch (error) {
        }
    }
}

const getPortfolioSummary = () => {
    return async (dispatch) => {
        try {
            const response = await apiServices.getPortfolioSummary();
            dispatch(setPortfolioSummary(response?.result));
        } catch (error) {
        }
    }
}

export {
    getMarketSummary,
    getPortfolioSummary,
};