import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transferInfo: null,
  beneficiaryData: null,
};

const tradingSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    setTransferInfo: (state, action) => {
      state.transferInfo = action.payload;
    },
    setBeneficiaryData: (state, action) => {
      state.beneficiaryData = action.payload;
    },
  },
});

export const { setTransferInfo, setBeneficiaryData } = tradingSlice.actions;
export default tradingSlice.reducer;
