import { configureStore } from '@reduxjs/toolkit';
import contentReducer from './slices/contentSlice';
import authReducer from './slices/authSlice';
import investmentsReducer from './slices/investmentsSlice';
import settingReducer from './slices/settingSlice';
import tradingReducer from './slices/tradingSlice';
import transferReducer from './slices/transferSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  content: contentReducer,
  auth: authReducer,
  investments: investmentsReducer,
  setting: settingReducer,
  trading: tradingReducer,
  transfer: transferReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export default store;
