import React from 'react'
import ViewReverse from '../../../components/ViewReverse'
import Text from '../../../components/Text'
import dynamicfunction from '../../../utils/dynamicfunction'
import { fontSizes } from '../../../constants/constants'

const Allocation = () => {
  const { TextWeight } = dynamicfunction();
  return (
    <div>
      <ViewReverse className={"items-end justify-between mb-4"}>
            <Text
              text={`Managed investments: Allocation`}
              weight={TextWeight.headingBold}
              size={fontSizes.h24}
              color={"text-dark-blue"}
            />
            <ViewReverse className={"items-center gap-2"}>
              <Text text={"A"} color={"text-dark-grey"} />
              <Text
                text={"A"}
                size={fontSizes.h16}
                color={"text-dark-grey"}
                className={"mb-[1px]"}
              />
              <Text
                text={"A"}
                size={fontSizes.h20}
                color={"text-dark-grey"}
                className={"mb-[2px]"}
              />
            </ViewReverse>
          </ViewReverse>
    </div>
  )
}

export default Allocation