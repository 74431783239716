import apiServices from "../../ApiServices/request-handler";
import { showToast } from "../../utils/utils";
import { onLogout } from "../slices/authSlice";
import { setLanguage } from "../slices/contentSlice";
import { setSettingLoading } from "../slices/settingSlice";

export const getLanguage = () => {
    return async dispatch => {
        try {
            const response = await apiServices.getLanguages();
            // dispatch(setLanguage({language}));
            console.log(response);
        } catch (error) {
            console.log('errrrr', error);
        }
    }
}

export const changeLanguage = id => {
    return async dispatch => {
        try {
            dispatch(setSettingLoading(true))
            const response = await apiServices.changeLanguage(id);
            if (response?.status === true) {
                dispatch(setLanguage({language: response?.result?.[0]?.name}))
                dispatch(setSettingLoading(false))
            } else {
                showToast('error', response?.data?.message)
                dispatch(setSettingLoading(false))
            }
        } catch (error) {
            console.log('errr', error)
            showToast('error', error?.message)
            dispatch(setSettingLoading(false))
        }
    };
};

export const changePassword = data => {
    return async dispatch => {
        try {
            dispatch(setSettingLoading(true));
            const response = await apiServices.changePassword(data);
            dispatch(setSettingLoading(false));
            if (response?.status) {
                dispatch(onLogout())
                window.location.reload();
            } else {
                showToast(response?.data?.message)
            }
        } catch (error) {
            console.log('err', error)
            showToast('error', error?.data?.message || error?.data?.title)
            dispatch(setSettingLoading(false));
        }
    };
}