import { useEffect, useState } from "react";
import Text from "./Text";
import { fontSizes } from "../constants/constants";
import dynamicfunction from "../utils/dynamicfunction";
import { ReactSVG } from "react-svg";
import icons from "../constants/icons";
import ViewItems from "./ViewItems";
import ViewReverse from "./ViewReverse";

const CustomDropdown = ({
  label,
  options,
  selectedValue,
  onSelect,
  className,
  isBorder = true,
  justify = "justify-between",
  top = "top-16",
  children,
  getContentKey,
  width = "w-full",
}) => {
  const { TextWeight } = dynamicfunction();

  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (key) => {
    onSelect(key);
    setIsOpen(false);
  };

  return (
    <ViewItems className={`${width} relative ${className}`}>
      <Text text={label} size={fontSizes.h14} color={"text-primary-light"} />

      {children ? (
        <div onClick={() => setIsOpen(!isOpen)}>{children}</div>
      ) : (
        <ViewReverse
          onClick={() => setIsOpen(!isOpen)}
          className={`${
            isBorder ? "border" : ""
          } border-grey items-center ${justify} gap-3 rounded w-full px-3 py-[9.5px] mt-1 cursor-pointer`}
        >
          <Text
            text={
              options.find((option) => (option?.key || option?.id) === selectedValue)?.label ||
              options.find((option) => (option?.key || option?.id) === selectedValue)?.name ||
              "Select"
            }
            size={fontSizes.h14}
            weight={TextWeight.light}
          />
          <ReactSVG src={icons.dropdownArrow} />
        </ViewReverse>
      )}

      {isOpen ? (
        <div
          className={`absolute z-50 bg-white ${top} w-full rounded border border-grey max-h-40 overflow-y-scroll`}
          // style={{zIndex: 10000}}
        >
          {options?.map((option, index) => (
            <div
              key={option.key}
              className={`cursor-pointer border-b ${
                index !== options.length
                  ? "border-b-grey"
                  : "border-transparent"
              }
              bg-white
              hover:bg-gray-200
              `}
              onClick={() => handleSelect(option?.key || option?.id)}
            >
              <Text
                text={getContentKey ? getContentKey(option.key) : option?.label || option?.name}
                size={fontSizes.h14}
                weight={TextWeight.light}
                className={"px-3 py-3"}
              />
            </div>
          ))}
        </div>
      ) : null}
    </ViewItems>
  );
};

export default CustomDropdown;
