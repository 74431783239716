import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!token?.accessToken) {
        navigate("/login");
    }
  }, []);

  return <div>{children}</div>;
};

export default ProtectedRoute;
