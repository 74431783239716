import React, { useState } from "react";
import Text from "../../../components/Text";
import { fontSizes } from "../../../constants/constants";
import dynamicfunction from "../../../utils/dynamicfunction";
import CustomDropdown from "../../../components/CustomDropdown";
import { dropdownOptions } from "../../../config/config";
import ViewReverse from "../../../components/ViewReverse";
import CustomInput from "../../../components/CustomInput";
import CustomCheckbox from "../../../components/CustomCheckbox";
import Button from "../../../components/Button";
import { ReactSVG } from "react-svg";
import icons from "../../../constants/icons";
import Divider from "../../../components/Divider";
import ViewItems from "../../../components/ViewItems";
import { useSelector } from "react-redux";
import { getContentKeyAccToLang } from "../../../utils/utils";
import { rowReverseStyle, rowReverseStylesm } from "../../../utils/styles";

const Subscribe = () => {
  const { TextWeight, isArabic } = dynamicfunction();
  const { fundsContent } = useSelector(state => state.content)

  const [subscriptionData, setSubscriptionData] = useState({
    asset: "",
    fund: "",
    account: "",
    riskAgreement: false,
    fundAgreement: false,
  });
  const [showTermModel, setShowTermModal] = useState(false);
  const [process, setProcess] = useState("inProgress");

  const getContentKey = (key) => {
    return getContentKeyAccToLang(fundsContent?.web_funds_subscribe?.data?.attributes, key, isArabic);
  }

  const handleSubscription = (key, val) =>
    setSubscriptionData((prev) => ({ ...prev, [key]: val }));

  const toogleTermModal = () => setShowTermModal(!showTermModel);

  const handleAcceptTerms = () => {
    setProcess("summary");
    setShowTermModal(!showTermModel);
  };

  return (
    <div className="h-full">
          <Text
            text={process !== "submit" ? getContentKey("heading") : ""}
            size={fontSizes.h24}
            weight={TextWeight.headingBold}
            color="text-dark-blue"
          />

      {process === "inProgress" ? (
        <div>
          <Text
            text={getContentKey("complete_subscription_request")}
            size={fontSizes.h20}
            weight={TextWeight.regular}
            color="text-light-blue"
            className={"mb-3"}
          />

          <ViewReverse className={"gap-4"}>
            <div className="w-full md:w-1/2">
              <CustomDropdown
                label={getContentKey("asset_class")}
                options={dropdownOptions}
                selectedValue={subscriptionData.asset}
                onSelect={(val) => handleSubscription("asset", val)}
              />
            </div>
            <div className="hidden md:block w-1/2" />
          </ViewReverse>

          <ViewReverse className={`mt-4 gap-4 flex-col ${rowReverseStylesm(isArabic)}`}>
            <div className="w-full md:w-1/2">
              <CustomDropdown
                label={getContentKey("fund")}
                options={dropdownOptions}
                selectedValue={subscriptionData.fund}
                onSelect={(val) => handleSubscription("fund", val)}
              />
            </div>
            <div className="w-full md:w-1/2">
              <CustomDropdown
                label={getContentKey("cash_account")}
                options={dropdownOptions}
                selectedValue={subscriptionData.account}
                onSelect={(val) => handleSubscription("account", val)}
              />
            </div>
          </ViewReverse>

          <ViewReverse className={`mt-4 gap-4 flex-col sm:${rowReverseStyle(isArabic)}`}>
            <div className="w-full md:w-1/2">
              <CustomInput
                label={getContentKey("class")}
                placeholder={getContentKey("class_placeholder")}
                isFullborder
                isRounded
              />
            </div>
            <div className="w-full md:w-1/2">
              <Text
                text={getContentKey("available_balance")}
                color="text-primary-light"
                size={fontSizes.h14}
              />
              <ViewReverse className={"gap-4 items-center pt-4"}>
                <Text
                  text={"3,292,035.9"}
                  color="text-grak-grey"
                  size={fontSizes.h14}
                  weight={TextWeight.light}
                />
                <Text
                  text={getContentKey("sar")}
                  color="text-grak-grey"
                  size={fontSizes.h14}
                  weight={TextWeight.light}
                />
              </ViewReverse>
            </div>
          </ViewReverse>

          <ViewReverse className={"mt-4 gap-4"}>
            <ViewReverse className="w-full sm:w-1/2 gap-4">
              <CustomInput
                label={getContentKey("subscription_amount")}
                placeholder={getContentKey("subscription_amount_placeholder")}
                isFullborder
                isRounded
              />
              <div>
                <Text
                  text={getContentKey("currency")}
                  color="text-primary-light"
                  size={fontSizes.h14}
                />
                <Text
                  text={getContentKey("sar")}
                  color="text-grak-grey"
                  size={fontSizes.h14}
                  weight={TextWeight.light}
                  className={"mt-4"}
                />
              </div>
            </ViewReverse>
            <div className="hidden sm:block w-1/2" />
          </ViewReverse>

          <CustomCheckbox
            label={getContentKey("understand_agree")}
            secondText={getContentKey("risk")}
            isChecked={subscriptionData.riskAgreement}
            onClick={() =>
              handleSubscription(
                "riskAgreement",
                !subscriptionData.riskAgreement
              )
            }
            className={"mt-8"}
          />
          <CustomCheckbox
            label={getContentKey("understand_agree")}
            secondText={getContentKey("fund_document")}
            isChecked={subscriptionData.fundAgreement}
            onClick={() =>
              handleSubscription(
                "fundAgreement",
                !subscriptionData.fundAgreement
              )
            }
            className={"mt-4"}
          />

          <ViewReverse className="items-center w-full justify-end gap-2 mt-8">
            <Button
              text={getContentKey("clear_btn")}
              bgColor="bg-white"
              color={"text-dark-grey"}
              className={
                "w-36 text-center border border-dark-grey border-opacity-50"
              }
            />
            <Button
              text={getContentKey("review_btn")}
              bgColor="bg-orange"
              className={"w-36 text-center border border-orange"}
              onClick={toogleTermModal}
            />
          </ViewReverse>
        </div>
      ) : process === "summary" ? (
        <ViewItems>
          <Text
            text={getContentKey("summary_subscription_request")}
            size={fontSizes.h20}
            weight={TextWeight.regular}
            color="text-light-blue"
            className={"mb-2"}
          />

          <ViewReverse className={"gap-4 w-full sm:w-1/2"}>
            <div className={"w-1/4 h-[1px] bg-grey my-3"} />
            <div className={"w-full h-[1px] bg-grey my-3"} />
          </ViewReverse>

          <ViewReverse className={"gap-4 w-full sm:w-1/2"}>
            <Text
              text={getContentKey("type")}
              size={fontSizes.h14}
              color={"text-primary-light"}
              className={"w-1/5"}
            />
            <Text text={"New"} size={fontSizes.h14} weight={TextWeight.light} />
          </ViewReverse>

          <ViewReverse className={"gap-4 w-full sm:w-1/2"}>
            <div className={"w-1/4 h-[1px] bg-grey my-3"} />
            <div className={"w-full h-[1px] bg-grey my-3"} />
          </ViewReverse>

          <ViewReverse className={"gap-4 w-full sm:w-1/2"}>
            <Text
              text={getContentKey("fund")}
              size={fontSizes.h14}
              color={"text-primary-light"}
              className={"w-1/5"}
            />
            <Text
              text={"Alkhobar"}
              size={fontSizes.h14}
              weight={TextWeight.light}
            />
          </ViewReverse>

          <ViewReverse className={"gap-4 w-full sm:w-1/2 mt-2"}>
            <Text
              text={getContentKey('class')}
              size={fontSizes.h14}
              color={"text-primary-light"}
              className={"w-1/5"}
            />
            <Text
              text={"Reserves"}
              size={fontSizes.h14}
              weight={TextWeight.light}
            />
          </ViewReverse>

          <ViewReverse className={"gap-4 w-full sm:w-1/2 mt-2"}>
            <Text
              text={getContentKey("amount")}
              size={fontSizes.h14}
              color={"text-primary-light"}
              className={"w-1/5"}
            />
            <Text
              text={"$33,000 USD"}
              size={fontSizes.h14}
              weight={TextWeight.light}
            />
          </ViewReverse>

          <ViewReverse className={"gap-4 w-full sm:w-1/2"}>
            <div className={"w-1/4 h-[1px] bg-grey my-3"} />
            <div className={"w-full h-[1px] bg-grey my-3"} />
          </ViewReverse>

          <ViewReverse className={"gap-4 w-full sm:w-1/2"}>
            <Text
              text={getContentKey("date")}
              size={fontSizes.h14}
              color={"text-primary-light"}
              className={"w-1/5"}
            />
            <Text
              text={"29 February 2021"}
              size={fontSizes.h14}
              weight={TextWeight.light}
            />
          </ViewReverse>

          <ViewReverse className={"gap-4 w-full sm:w-1/2"}>
            <div className={"w-1/4 h-[1px] bg-grey my-3"} />
            <div className={"w-full h-[1px] bg-grey my-3"} />
          </ViewReverse>

          <div className="h-32" />

          <ViewReverse className="items-center w-full justify-end gap-2 mt-8">
            <Button
              text={getContentKey("edit_btn")}
              bgColor="bg-white"
              color={"text-dark-grey"}
              className={
                "w-36 text-center border border-dark-grey border-opacity-50"
              }
              onClick={() => setProcess("inProgress")}
            />
            <Button
              text={getContentKey("submit_btn")}
              bgColor="bg-orange"
              className={"w-36 text-center border border-orange"}
              onClick={() => setProcess("submit")}
            />
          </ViewReverse>
        </ViewItems>
      ) : (
        <div className="w-full flex flex-col justify-center items-center h-[50vh]">
          <div>
            <Text
              text={getContentKey("thank_you")}
              size={fontSizes.h24}
              weight={TextWeight.headingBold}
              color="text-dark-blue"
            />
            <Text
              text={getContentKey("subscription_recieved")}
              size={fontSizes.h20}
              weight={TextWeight.regular}
              color="text-light-blue"
            />
          </div>
        </div>
      )}

      {showTermModel && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30">
          <div className="bg-white rounded w-11/12 md:w-3/4">
            <ViewReverse
              className={
                "items-center py-2 px-8 justify-between bg-white shadow-lg rounded-tr rounded-tl"
              }
            >
              <Text
                text={getContentKey("fund_document")}
                size={fontSizes.h24}
                weight={TextWeight.headingBold}
                color="text-dark-blue"
              />
              <ReactSVG
                src={icons.cross}
                onClick={toogleTermModal}
                className="cursor-pointer"
              />
            </ViewReverse>
            <div className="w-full h-40"></div>
            <ViewReverse
              className={
                "items-center justify-end rounded py-4 px-8 bg-white gap-4"
              }
            >
              <Button
                text={getContentKey("accept_btn")}
                bgColor="bg-orange"
                className={"border border-orange text-center w-32 px-0"}
                onClick={handleAcceptTerms}
              />
              <Button
                text={getContentKey("decline_btn")}
                bgColor="bg-white"
                color={"text-dark-grey"}
                className={
                  "text-center w-32 px-0 border border-dark-grey border-opacity-50"
                }
                onClick={toogleTermModal}
              />
            </ViewReverse>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscribe;
