import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  authLoading: false,
  loginError: null,
  otpError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload.user;
    },
    setUserToken: (state, action) => {
      state.token = action.payload.token;
    },
    setAuthLoading: (state, action) => {
      state.authLoading = action.payload;
    },
    setLoginError: (state, action) => {
      state.loginError = action.payload;
    },
    setOtpError: (state, action) => {
      state.otpError = action.payload;
    },
    onLogout: (state, action) => {
      state.user = null;
      state.token = null;
    }
  },
});

export const { setAuthLoading, setUserData, setLoginError, setOtpError, setUserToken, onLogout } = authSlice.actions;
export default authSlice.reducer;
