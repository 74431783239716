import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import dynamicfunction from "../../utils/dynamicfunction";
import { useState } from "react";
import { setLoginError, setOtpError } from "../../store/slices/authSlice";
import {
  loginUser,
  resendOtpCode,
  verifyUserOtp,
} from "../../store/actions/authActions";
import { getContentKeyAccToLang, showToast } from "../../utils/utils";

export default () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authLoading, loginError, otpError } = useSelector(
    (state) => state.auth
  );
  const { authContent } = useSelector((state) => state.content);

  const { TextWeight, isArabic } = dynamicfunction();
  const [isCode, setIsCode] = useState(false);
  const [isCountStart, setIsCountStart] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    userName: "",
    password: "",
  });
  const [otp, setOtp] = useState("");
  const [isResendCode, setIsResendCode] = useState(false);
  const [timerKey, setTimerKey] = useState(0);

  const onChange = (value) => {};

  const handleLogin = async () => {
    try {
      dispatch(setLoginError(null));

      if (!loginDetails.userName) {
        dispatch(setLoginError("Username is required"));
      } else if (!loginDetails.password) {
        dispatch(setLoginError("Password is required"));
      } else {
        dispatch(
          loginUser(loginDetails, () => {
            showToast("success", getContentKey("otp_send"));
            setIsCode(true);
            setIsCountStart(true);
          })
        );
      }
    } catch (error) {
      console.log("errr", error);
    }
  };

  const handleVerifyOtp = () => {
    try {
      dispatch(setOtpError(null));

      const data = {
        otpCode: otp,
        userName: loginDetails.userName,
      };

      dispatch(verifyUserOtp(data, () => navigate("/dashboard")));
    } catch (error) {
      console.log("errr", error);
    }
  };

  const handleResendCode = () => {
    try {
      dispatch(
        resendOtpCode({ userName: loginDetails.userName }, () => {
          showToast("success", getContentKey("otp_resend"));
          
          setIsResendCode(false);
          setTimerKey(prevKey => prevKey + 1);
        })
      );
    } catch (error) {}
  };

  const onBack = () => {
    setIsCode(false);
    setIsCountStart(false);
  };

  const hnadleInputChange = (key, value) => {
    setLoginDetails((prev) => ({ ...prev, [key]: value }));
  };

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      authContent?.web_login?.data?.attributes,
      key,
      isArabic
    );
  };

  return {
    TextWeight,
    isArabic,
    authLoading,
    loginError,
    otpError,
    loginDetails,
    isCode,
    otp,
    setOtp,
    isCountStart,
    isResendCode,
    timerKey,
    setIsResendCode,
    hnadleInputChange,
    onChange,
    handleLogin,
    handleVerifyOtp,
    handleResendCode,
    onBack,
    getContentKey,
  };
};
